import {
    BrowserRouter,
    Routes,
    Route,
    Link,
} from "react-router-dom";
import { lazy } from 'react';
import FrontPage from "../pages/FrontPage/FrontPage";
import TopMenu from "../components/TopMenu/TopMenu";
import InformationAboutService from "../pages/InformationAboutService/InformationAboutService";
import CommentsPage from "../pages/Comments/CommentsPage";
import CustomAreasMap from "../pages/CustomAreas/CustomAreasMap";
import { Suspense, useContext } from "react";
import { AppSettingsContext } from "./AppSettingsContextContainer";
import MobileMenu from "../components/MobileMenu/MobileMenu";
import styled from "styled-components";
import { Typography } from "antd";
import UserDataContextContainer from "../pages/CustomAreas/UserDataContextContainer";
import LanguageSelector from "../components/LanguageSelector/LanguageSelector";
import 'antd/dist/reset.css';
import FrontPageSuspended from "../pages/FrontPage/FrontPageSuspended";
import TopMenuSuspended from "../components/TopMenu/TopMenuSuspended";

const ForestsNearUsPage = lazy(() => import("../pages/ForestsNearUs/ForestsNearUsPage"));
const HistoryPage = lazy(() => import("../pages/History/HistoryPage"));
const ObservationsPage = lazy(() => import("../pages/Observations/ObservationsPage"));
const HabitatsPage = lazy(() => import("../pages/Habitats/HabitatsPage"));
const AlertsConfigurationPage = lazy(() => import("../pages/AlertsConfiguration/AlertsConfigurationPage"));
const AlertsOrderingPage = lazy(() => import("../pages/AlertsConfiguration/AlertsOrderingPage"));
const AlertsRemovalPage = lazy(() => import("../pages/AlertsConfiguration/AlertsRemovalPage"));
const AlertsActivationPage = lazy(() => import("../pages/AlertsConfiguration/AlertsActivationPage"));
const NaturalForestsPage = lazy(() => import("../pages/NaturalForests/NaturalForestsPage"));
const METSOPotentialPage = lazy(() => import("../pages/METSOPotential/METSOPotentialPage"));

const SiteTitle = styled.div`
  margin-left: 3%;
  color: "white";
  width: 100%;

  @media screen and (min-width: 992px) {
		margin-right: 10%;
		margin-left: 10%;
  }
`;

interface AppHeaderProps {
    background: string
}

const AppHeader = styled.div<AppHeaderProps>`
	background-color: ${props => props.background};
	height: 2em;
    min-height: 2em;
	display: flex;
	font-size: 1.4rem;
	width: 100%;
	align-items: center;  
	color: white;
`;

export default function MainContent() {
    const appSettingsContext = useContext(AppSettingsContext);

    return (
        <BrowserRouter>
            {appSettingsContext.isMobile &&
                <AppHeader background={appSettingsContext.colors[6] ?? ""}>
                    {<SiteTitle>
                        <Link to="/">
                            <Typography.Title level={1} style={{ color: "black", marginBottom: "0" }}>Luontovahti</Typography.Title>
                        </Link>
                    </SiteTitle>}
                    <Suspense fallback={<></>}>
                        <LanguageSelector />
                        <MobileMenu />
                    </Suspense>
                </AppHeader>
            }
            {appSettingsContext.isMobile === false && <Suspense fallback={<TopMenuSuspended />}><TopMenu /></Suspense>}
            <Routes>
                <Route path="/" element={<Suspense fallback={<FrontPageSuspended />}><FrontPage /></Suspense>}></Route>
                <Route path="/luonnonmetsat" element={<Suspense fallback={<></>}><NaturalForestsPage /></Suspense>}></Route>
                <Route path="/hakkuutjalajit" element={<Suspense fallback={<></>}><ObservationsPage /></Suspense>}></Route>
                <Route path="/hakkuutjaelinymparistot" element={<Suspense fallback={<></>}><HabitatsPage /></Suspense>}></Route>
                <Route path="/hakkuidenhistoriaa" element={<Suspense fallback={<></>}><HistoryPage /></Suspense>}></Route>
                <Route path="/lahimetsamme" element={<Suspense fallback={<></>}><ForestsNearUsPage /></Suspense>}></Route>
                <Route path="/vanhojametsia" element={<Suspense fallback={<></>}><METSOPotentialPage /></Suspense>}></Route>
                <Route path="/omatalueet" element={<UserDataContextContainer>
                    <CustomAreasMap />
                </UserDataContextContainer>}>
                </Route>
                <Route path="/palaute" element={<Suspense fallback={<></>}><CommentsPage /></Suspense>}></Route>
                <Route path="/halytykset/" element={<Suspense fallback={<></>}><AlertsOrderingPage /></Suspense>}></Route>
                <Route path="/halytykset/aktivointi" element={<Suspense fallback={<></>}><AlertsActivationPage /></Suspense>}></Route>
                <Route path="/halytykset/poisto" element={<Suspense fallback={<></>}><AlertsRemovalPage /></Suspense>}></Route>
                <Route path="/halytykset/muuta" element={<Suspense fallback={<></>}><AlertsConfigurationPage /></Suspense>}></Route>
                <Route path="/ilmoitustenaktivointi" element={<Suspense fallback={<></>}><AlertsActivationPage /></Suspense>}></Route>
                <Route path="/tietojapalvelusta" element={<Suspense fallback={<></>}><InformationAboutService /></Suspense>}></Route>
                <Route path="*" element={<span>Sivua ei löydy</span>} />
            </Routes >
        </BrowserRouter >
    )
}