const notificationsLegendData = {
    "layers": [
        {
            "layerId": 0,
            "layerName": "ATVA.MKI_KUVIO",
            "layerType": "Feature Layer",
            "minScale": 100000,
            "maxScale": 0,
            "legend": [
                {
                    "nameFi": "Ylispuiden poisto",
                    "nameEn": "Cutting of hold-overs",
                    "nameSv": "Avverkning av överståndare",
                    "url": "039910d5417cda79e65cdb186e58ce16",
                    "imageData": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAEZJREFUOI1jYaAyYKGZgTk5Of8pNWzKlCmMKC7cfVqGbMNcTZ8wMDDQ0sujBo4aOGrgqIE4DYQVQeSAKVOmMKIYCBOgFAAAv1AMKRhj8M0AAAAASUVORK5CYII=",
                    "contentType": "image/png",
                    "height": 20,
                    "width": 20,
                    "values": [
                        "1"
                    ]
                },
                {
                    "nameFi": "Ensiharvennus",
                    "nameEn": "First thinning",
                    "nameSv": "Första gallring",
                    "url": "d061404dd1daf0d9ab1f9eca34174844",
                    "imageData": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAEZJREFUOI1jYaAyYKGZgTk5Of8pNWzKlCmMKC4Mur+WbMPWKQYzMDDQ0sujBo4aOGrgqIE4DYQVQeSAKVOmMKIYCBOgFAAAq6oL7XyJKjcAAAAASUVORK5CYII=",
                    "contentType": "image/png",
                    "height": 20,
                    "width": 20,
                    "values": [
                        "2"
                    ]
                },
                {
                    "nameFi": "Harvennushakkuu",
                    "nameEn": "Thinning",
                    "nameSv": "Gallring",
                    "url": "869a20523cb4ad76d9e682c2f1812ecb",
                    "imageData": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAEZJREFUOI1jYaAyYKGZgTk5Of8pNWzKlCmMKC5cJniFbMOi3uswMDDQ0sujBo4aOGrgqIE4DYQVQeSAKVOmMKIYCBOgFAAAyAUMQDjrXMsAAAAASUVORK5CYII=",
                    "contentType": "image/png",
                    "height": 20,
                    "width": 20,
                    "values": [
                        "3"
                    ]
                },
                {
                    "nameFi": "Kaistalehakkuu",
                    "nameEn": "Strip cutting",
                    "nameSv": "Remsavverkning",
                    "url": "55679c319483c61960c5a9d53c4abb13",
                    "imageData": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAEZJREFUOI1jYaAyYKGZgTk5Of8pNWzKlCmMKC7kPTKFbMM+2+QwMDDQ0sujBo4aOGrgqIE4DYQVQeSAKVOmMKIYCBOgFAAA1W4MZvHQVAgAAAAASUVORK5CYII=",
                    "contentType": "image/png",
                    "height": 20,
                    "width": 20,
                    "values": [
                        "4"
                    ]
                },
                {
                    "nameFi": "Avohakkuu",
                    "nameEn": "Clear cutting",
                    "nameSv": "Kalhygge",
                    "url": "af7996bce16ff564f81c5641a6345823",
                    "imageData": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAEZJREFUOI1jYaAyYKGZgTk5Of8pNWzKlCmMKC60SDtCtmEnZtkwMDDQ0sujBo4aOGrgqIE4DYQVQeSAKVOmMKIYCBOgFAAA1nsMaUyE+7UAAAAASUVORK5CYII=",
                    "contentType": "image/png",
                    "height": 20,
                    "width": 20,
                    "values": [
                        "5"
                    ]
                },
                {
                    "nameFi": "Verho-, suojus-, tai siemenpuuhakkuu",
                    "nameEn": "Shelterwood or seedling cutting",
                    "nameSv": "Skydds-, skydds- eller fröträdsavverkning",
                    "url": "0e3ffac07e32e9a5db36a2732650e072",
                    "imageData": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAEZJREFUOI1jYaAyYKGZgTk5Of8pNWzKlCmMKC7Uem5CtmHXJM8wMDDQ0sujBo4aOGrgqIE4DYQVQeSAKVOmMKIYCBOgFAAA3/EMhmgqihIAAAAASUVORK5CYII=",
                    "contentType": "image/png",
                    "height": 20,
                    "width": 20,
                    "values": [
                        "6",
                        "7",
                        "8"
                    ]
                },
                {
                    "nameFi": "Maankäyttömuodon muutos",
                    "nameEn": "Change of land use",
                    "nameSv": "Ändring av markanvändning",
                    "url": "9339fecefb065f6634eb3b0760c6c77b",
                    "imageData": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAENJREFUOI1jYaAyYKGZgTk5Of8pNWzKlCmMLGgCZBuWk5PDwMBASy+PGjhq4KiBowbiNBBWBJEDpkyZwohiIEyAUgAAtv8MD/tQFpMAAAAASUVORK5CYII=",
                    "contentType": "image/png",
                    "height": 20,
                    "width": 20,
                    "values": [
                        "9",
                        "10",
                        "15",
                        "16",
                        "18"
                    ]
                },
                {
                    "nameFi": "Erityishakkuu, ML 5b",
                    "nameEn": "Special cutting, ML 5b",
                    "nameSv": "Specialavverkning, ML 5b",
                    "url": "34dd3949a3ec4f3161fe8f1ad2851e67",
                    "imageData": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAEVJREFUOI1jYaAyYKGZgTk5Of8pNWzKlCmMKC4snaJHtmHdOZcYGBho6eVRA0cNHDVw1ECcBsKKIHLAlClTGFEMhAlQCgDkcAyUuPJsrwAAAABJRU5ErkJggg==",
                    "contentType": "image/png",
                    "height": 20,
                    "width": 20,
                    "values": [
                        "11"
                    ]
                },
                {
                    "nameFi": "Eri-ikäisyyteen tähtäävä hakkuu",
                    "nameEn": "Cutting for uneven-aged forest",
                    "nameSv": "Avverkning för ojämnåldrig skog",
                    "url": "fa50d388f7323ccaefebf68d3a700cf0",
                    "imageData": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAEZJREFUOI1jYaAyYKGZgTk5Of8pNWzKlCmMKC7Umj2bbMOupaYyMDDQ0sujBo4aOGrgqIE4DYQVQeSAKVOmMKIYCBOgFAAA1xAMa5I80ZEAAAAASUVORK5CYII=",
                    "contentType": "image/png",
                    "height": 20,
                    "width": 20,
                    "values": [
                        "12",
                        "17"
                    ]
                },
                {
                    "nameFi": "Kasvatushakkuu, paperi-ilmoitus",
                    "nameEn": "Intermediate cutting, paper notification",
                    "nameSv": "Föryngringsavverkning, pappersanmälan",
                    "url": "48e90331e15739a304885230aec79e14",
                    "imageData": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAEZJREFUOI1jYaAyYKGZgTk5Of8pNWzKlCmMKC68ffs22YapqqoyMDDQ0sujBo4aOGrgqIE4DYQVQeSAKVOmMKIYCBOgFAAAbccLOlYkrDgAAAAASUVORK5CYII=",
                    "contentType": "image/png",
                    "height": 20,
                    "width": 20,
                    "values": [
                        "13"
                    ]
                },
                {
                    "nameFi": "Muu hakkuu",
                    "nameEn": "Other cutting",
                    "nameSv": "Annat avverkning",
                    "url": "c8a24f5c5cee6dac0e0356e19e1c1ac4",
                    "imageData": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAEZJREFUOI1jYaAyYKGZgTk5Of8pNWzKlCmMKC4scN1MtmETdvsyMDDQ0sujBo4aOGrgqIE4DYQVQeSAKVOmMKIYCBOgFAAA1CIMY/ThhscAAAAASUVORK5CYII=",
                    "contentType": "image/png",
                    "height": 20,
                    "width": 20,
                    "values": [
                        "14"
                    ]
                },
                {
                    "nameFi": "Uudistamishakkuu, paperi-ilmoitus",
                    "nameEn": "Regeneration cutting, paper notification",
                    "nameSv": "Föryngringsavverkning, pappersanmälan",
                    "url": "b1c6171fd8a5938e2da2f6ef21468bb6",
                    "imageData": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAEVJREFUOI1jYaAyYKGZgTk5Of8pNWzKlCmMKC4s1f5FtmHdV9kYGBho6eVRA0cNHDVw1ECcBsKKIHLAlClTGFEMhAlQCgDDNAwxHSmYaQAAAABJRU5ErkJggg==",
                    "contentType": "image/png",
                    "height": 20,
                    "width": 20,
                    "values": [
                        "19"
                    ]
                },
                {
                    "nameFi": "Myrskytuhohakkuu",
                    "nameEn": "Storm damage cutting",
                    "nameSv": "Stormskadegallring",
                    "url": "1b16c596680f84649c1f49000587c0c2",
                    "imageData": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAEZJREFUOI1jYaAyYKGZgTk5Of8pNWzKlCmMKC78uuwm2YZxR6kzMDDQ0sujBo4aOGrgqIE4DYQVQeSAKVOmMKIYCBOgFAAAd6MLV92KXLMAAAAASUVORK5CYII=",
                    "contentType": "image/png",
                    "height": 20,
                    "width": 20,
                    "values": [
                        "20",
                        "21"
                    ]
                },
                {
                    "nameFi": "Hyönteistuhohakkuu",
                    "nameEn": "Insect damage cutting",
                    "nameSv": "Insektsskadegallring",
                    "url": "a2d0d60122c1371f46efab55c58f7f84",
                    "imageData": "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAEZJREFUOI1jYaAyYKGZgTk5Of8pNWzKlCmMKC6cEvWZbMNylvEyMDDQ0sujBo4aOGrgqIE4DYQVQeSAKVOmMKIYCBOgFAAAqqYL6o3R2gAAAAAASUVORK5CYII=",
                    "contentType": "image/png",
                    "height": 20,
                    "width": 20,
                    "values": [
                        "22",
                        "23"
                    ]
                }
            ]
        }
    ]
};

export default notificationsLegendData;