import React, { useContext } from 'react'
import styled from 'styled-components';
import MapMenu, { IMenuOpenState } from '../../components/MapMenu/MapMenu';
import Map from './components/Map/Map';
import { InfoIcon } from '../../components/MapMenu/Icons/InfoIcon';
import MobileMenuContents from './components/Menu/MobileMenuContents';
import Wizard from '../../components/Wizard/Wizard';
import NewUserAreaWizard from './components/NewUserAreaWizard/NewUserAreaWizard';
import { UserDataContext } from './UserDataContextContainer';
import { AppSettingsContext } from '../../App/AppSettingsContextContainer';

interface IMapContainerProps {
    isMobile: boolean
}

const MapContainer = styled.div<IMapContainerProps>`
    height: 100%;

    display: flex;

    flex-direction: ${props => props.isMobile ? "column" : "row"};
`;

interface StyleProps {
    isShown: boolean
}

const WizardContainer = styled.div<StyleProps>`
    display: ${props => props.isShown ? "block" : "none"};
    width: min(100%, 30em);
`;

const Icons = [
    <InfoIcon></InfoIcon>,
];

export default function CustomAreasMapPage() {
    const userDataContext = useContext(UserDataContext);
    const appSettingsContext = useContext(AppSettingsContext);

    return (
        <MapContainer isMobile={appSettingsContext.isMobile}>
            <MapMenu icons={Icons} render={(state: IMenuOpenState) => <MobileMenuContents state={state} />} />
            <Map />
            <WizardContainer isShown={userDataContext.isAddingNew}>
                {userDataContext.isAddingNew ? <Wizard onCancel={userDataContext.cancelAddingNewArea}>
                    <></>
                    <NewUserAreaWizard></NewUserAreaWizard>
                </Wizard> : <></>}
            </WizardContainer>
        </MapContainer >
    )
}