import React, { ReactElement } from 'react'
import CommentForm from './CommentForm';
import { HeroImage } from '../FrontPage/FrontPage';
import { ContentContainer } from '../AlertsConfiguration/components/ContentContainer';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';

export default function CommentsPage(): ReactElement {
    const { t } = useTranslation("feedbackForm");

    return (
        <>
            <HeroImage>
                <ContentContainer>
                    <Typography.Title level={4}>{t("otsikko")}</Typography.Title>
                    <CommentForm></CommentForm>
                    <br /><br />
                </ContentContainer>
            </HeroImage>
        </>
    )
}
