import { useContext, useEffect, useMemo, useRef } from 'react';
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import { MapContext } from "../../../../App/MapContextContainer";
import { UserDataContext } from "../../UserDataContextContainer";
import Polygon from "@arcgis/core/geometry/Polygon";
import Graphic from "@arcgis/core/Graphic";
import { outlineOnlySymbol } from "./userAreaSymbol";
import SketchViewModel from "@arcgis/core/widgets/Sketch/SketchViewModel";
import { useSetUpMap } from "../../../../App/useSetUpMap";
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import SimpleRenderer from "@arcgis/core/renderers/SimpleRenderer";
import SimpleFillSymbol from "@arcgis/core/symbols/SimpleFillSymbol";
import { getNotificationsLayer } from '../../Utils';
import WFSLayer from '@arcgis/core/layers/WFSLayer';
import MapContainer from '../../../../components/Maps/MapContainer';
import { PREFERRED_WKID } from '../../../utils';

export default function Map() {
    const mapContext = useContext(MapContext);
    const userdataContext = useContext(UserDataContext);
    const elementRef = useRef(null);
    const { webmap, view } = useSetUpMap(elementRef, "6e34c9c7b08d41779b23bf1241649275");

    const rings = [
        [[30.342490498526395, 69.35310958409754], [30.34255391975965, 69.35310836087712], [30.34255108377997, 69.35309004868984], [30.34248766260028, 69.35309127190908], [30.342490498526395, 69.35310958409754]]
    ];

    const polygon = new Polygon({
        rings: rings,
        spatialReference: { wkid: PREFERRED_WKID }
    });

    let renderer = new SimpleRenderer({
        symbol: new SimpleFillSymbol({
            color: "red"
        })
    });

    const clashesFeatureLayer = new FeatureLayer({
        source: [new Graphic({ geometry: polygon, attributes: { "OBJECTID": "foobar" } })],
        objectIdField: "OBJECTID",
        title: "Päällekkäiset metsänkäyttöilmoitukset",
        renderer
    });

    const graphicsLayer = useMemo(() => new GraphicsLayer({ title: "Omat alueet" }), []);

    const sketchVM = new SketchViewModel({
        view: view,
        layer: graphicsLayer,
        updateOnGraphicClick: true,
        defaultUpdateOptions: {
            toggleToolOnClick: false // only reshape operation will be enabled
        }
    });

    sketchVM.on("create", function (event) {
        if (event.state === "complete") {
            userdataContext.newUserAreaDispatch({ type: "setGeography", geometry: event.graphic.geometry as Polygon });
        }
    });

    sketchVM.on("update", function (event) {
        // Do not allow user to modify the graphics just yet, so cancel the action immediately.
        if (userdataContext.isAddingNew === false) {
            sketchVM.cancel();
        }
        else {
            userdataContext.newUserAreaDispatch({ type: "setGeography", geometry: event.graphics[0].geometry as Polygon });
        }
    });

    useEffect(() => {
        if (mapContext.isSketching) {
            console.log("draw button clicked");

            sketchVM.create("rectangle");
        }
        else {
            console.log("removing");

            sketchVM.cancel();
        }
    }, [mapContext.isSketching]);

    useEffect(() => {
        graphicsLayer.removeAll();

        if (userdataContext.areas) {
            const checkUserAreaClashes = async () => {
                const notificationsLayer: WFSLayer = getNotificationsLayer(webmap);

                for (const value of userdataContext.areas) {
                    graphicsLayer.add(new Graphic({ geometry: value.geometry, symbol: outlineOnlySymbol }));

                    const notificationsOverlapping = await notificationsLayer.queryFeatures({
                        spatialRelationship: 'intersects',
                        where: "1=1",
                        outSpatialReference: { wkid: PREFERRED_WKID },
                        returnGeometry: true,
                        outFields: ["OBJECTID"],
                        geometry: value.geometry
                    });

                    if (notificationsOverlapping.features.length > 0) {
                        await clashesFeatureLayer.applyEdits({
                            addFeatures: notificationsOverlapping.features
                        });
                    }
                }
            }

            checkUserAreaClashes();
        }
    }, [userdataContext.areas, userdataContext.areas.length]);

    useEffect(() => {
        if (mapContext.mapAndViewReady) {
            webmap.add(graphicsLayer);
            webmap.add(clashesFeatureLayer);
        }
    }, [mapContext.mapAndViewReady, webmap]);

    return <MapContainer elementRef={elementRef} view={view}></MapContainer>
}
