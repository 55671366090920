import React, { ReactElement, useContext } from 'react';
import { IconWrapper } from './IconWrapper';
import { IMenuOpenState, MenuContext } from '../MapMenu';
import {
  InfoOutlined
} from '@ant-design/icons';

export function InfoIcon(): ReactElement {
  const context = useContext(MenuContext);

  return (<IconWrapper stateToShow={IMenuOpenState.main}>
      <InfoOutlined onClick={() => context.open(IMenuOpenState.main)}>
      </InfoOutlined>
  </IconWrapper>)
}
