import React, { ReactElement, useContext } from 'react';
import {
  CloseOutlined
} from '@ant-design/icons';
import { MenuContext } from '../MapMenu';

export function CloseIcon(): ReactElement {
  const context = useContext(MenuContext);

  return (
    <CloseOutlined onClick={() => context.close()}>
    </CloseOutlined>
  );
}
