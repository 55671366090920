import { whenFalse, whenTrue } from '@arcgis/core/core/watchUtils';
import MapView from '@arcgis/core/views/MapView';
import { Spin } from 'antd';
import React, { useContext, useState } from 'react'
import styled from 'styled-components';
import MapErrorContainer from './MapErrorContainer';
import { PageContext } from '../../App/PageContextContainer';
import { SidePanelToggleControlMobile } from '../SidePanel/SidePanelToggleControlMobile';
import { AppSettingsContext } from '../../App/AppSettingsContextContainer';
import { useTranslation } from 'react-i18next';
import DeclarationsFreshnessIndicator from './DeclarationsFreshnessIndicator';

const MapContainerDiv = styled.div`
    height: 100%; //calc(100vh - 4em);
    position: relative;

    @media screen and (min-width: 1080px) {
        width: 100%;
    }
`;

interface SpinContainerProps {
    spinnerVisible: boolean,
    useBackdropFilter: boolean
}

const SpinContainer = styled.div<SpinContainerProps>`
    display: ${p => p.spinnerVisible ? "block" : "none"};

    backdrop-filter: ${p => p.useBackdropFilter ? "blur(10px)" : ""};

    position: absolute;
    right: 10px;
    top: 10px;
`;

const SpinAndMessageContainer = styled.div`    
    display: flex;
    justify-content: flex-end;
`;

const SpinMessageContainer = styled.span`
    margin-right: 1em;
    width: 80%;
`;

interface IProps {
    elementRef: React.RefObject<HTMLDivElement> | null,
    view: MapView,
    customVisibilityCheckFn?: () => boolean
}

export default function MapContainer(props: IProps) {
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const pageContext = useContext(PageContext);
    const { isMobile } = useContext(AppSettingsContext);
    const { t, ready } = useTranslation("common");

    whenTrue(props.view, "updating", () => {
        !spinnerVisible && setSpinnerVisible(true);
    });

    whenFalse(props.view, "updating", () => {
        spinnerVisible && setSpinnerVisible(false);
    });

    return (
        <>
            <MapContainerDiv ref={props.elementRef}>
                {<SpinContainer spinnerVisible={spinnerVisible ||
                    (pageContext.thingsBeingLoaded && pageContext.thingsBeingLoaded.length > 0) ||
                    (props.customVisibilityCheckFn !== undefined && props.customVisibilityCheckFn() === true)}
                    useBackdropFilter={pageContext.thingsBeingLoaded && pageContext.thingsBeingLoaded.length > 0}>

                    <SpinAndMessageContainer>
                        {ready && pageContext.thingsBeingLoaded && pageContext.thingsBeingLoaded.length > 0 &&
                            <div>
                                <SpinMessageContainer>{t(pageContext.thingsBeingLoaded[pageContext.thingsBeingLoaded.length - 1].translationKey)}</SpinMessageContainer>
                            </div>}
                        <div>
                            <Spin size="large"></Spin>
                        </div>
                    </SpinAndMessageContainer>
                </SpinContainer>}
                <MapErrorContainer></MapErrorContainer>
                {isMobile && <SidePanelToggleControlMobile />}
                <DeclarationsFreshnessIndicator />
            </MapContainerDiv>
        </>
    )
}