import React from "react";
import { render } from "react-dom";
import './index.css';
import App from './App/App';
import reportWebVitals from './reportWebVitals';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// MSAL imports
import { PublicClientApplication, EventType, EventMessage, AuthenticationResult } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import HttpBackend, { HttpBackendOptions } from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

export const msalInstance = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpBackend)
  .init<HttpBackendOptions>({
    backend: {
      // http backend options
      loadPath: '/locales/{{ns}}/translations_{{lng}}.json',
      // reloadInterval: 60000,
      requestOptions: {
        mode: 'cors',
        credentials: 'same-origin',
        cache: 'default',
      }
    },
    react: {
      useSuspense: false,
    },

    // other i18next options
    // lng: "fi", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",
  });
// .init({
//   // the translations
//   // (tip move them in a JSON file and import them,
//   // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
//   resources: {
//     en: {
//       translation: {
//         "myKey": `Korkealta katsottuna luonnonmetsät on merkitty palluroilla, jotka ovat joko vihreitä (ei metsänkäyttöilmoituksia alueella 1.1.2019 eteenpäin), punaisia (metsänkäyttöilmoituksia alueella)
//         tai mustia (alue hakattu). <b>HUOM!</b> Suodattimista (valikossa <FilterOutlined />) voit säätää, minkä kuukauden jälkeen tulleita metsänkäyttöilmoituksia haluat kartalla näkyvän. Tämä vaikuttaa samalla myös siihen, näkyykö metsä vihreänä vai punaisena.`
//       }
//     }
//   },
//   lng: "en", // if you're using a language detector, do not define the lng option
//   fallbackLng: "en",

//   interpolation: {
//     escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
//   }
// });

const root = document.getElementById("root");
render(
  <React.StrictMode>
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_CAPTCHA_SITE_KEY ?? "wont-work"}>
      <App pca={msalInstance} />
    </GoogleReCaptchaProvider>
  </React.StrictMode>,
  root);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
