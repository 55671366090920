import { CaretLeftOutlined, CheckOutlined, ExclamationOutlined } from '@ant-design/icons';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components';
import { AppSettingsContext } from '../../App/AppSettingsContextContainer';
import { useTranslation } from 'react-i18next';

interface IContainerProps {
    bgColor: string
}

const ContainerDiv = styled.div<IContainerProps>`
    height: 40px;
    min-width: 40px;
    width: fit-content;

    display: flex;
    align-items: center;
    justify-content: center;

    border: 1px solid black;
    background-color: ${props => props.bgColor};
`;

interface IFreshnessData {
    declarationsCount: number,
    declarationsFrom: string
}

export default function DeclarationsFreshnessIndicator() {
    const [freshnessData, setFreshnessData] = useState<IFreshnessData | undefined>();
    const [daysSince, setDaysSince] = useState<number | undefined>();
    const { colors } = useContext(AppSettingsContext);
    const [isOpen, setIsOpen] = useState(true);
    const { t } = useTranslation("common");

    useEffect(() => {
        const asyncCall = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/HTTP_GetLatestDeclarationTime`);

                const data = await response.json();

                setFreshnessData(data);
            } catch (error) {
                console.log("Failed to get freshness data: ", error);
            }
        }

        asyncCall();
    }, []);

    useEffect(() => {
        if (freshnessData) {
            const daysSince = Math.floor((Date.now() - new Date(freshnessData.declarationsFrom).getTime()) / (1000 * 60 * 60 * 24));

            setDaysSince(daysSince);
        }
    }, [freshnessData]);

    const icon = useMemo(() => {
        if (daysSince !== undefined) {
            if (daysSince < 3) {
                return <CheckOutlined style={{ color: "green" }} />;
            }
            else if (daysSince < 5) {
                return <CheckOutlined style={{ color: "brown" }} />;
            }
            else {
                return <ExclamationOutlined style={{ color: "red" }} />;
            }
        }

        return <></>;
    }, [daysSince]);

    const toggle = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen]);

    return <ContainerDiv id="declarations-freshness-indicator" bgColor={colors[4]} style={{ "display": freshnessData ? "flex" : "none" }}>
        <div style={{ "fontSize": "2rem", "cursor": isOpen ? "default" : "pointer" }} onClick={isOpen ? () => { } : toggle}>{icon}</div>
        {isOpen && <>
            <div>{t("declarationAge", { daysSince })}</div>
            <div style={{ "cursor": "pointer", "width": "40px", "fontSize": "1.5rem", "display": "flex", "justifyContent": "center" }} onClick={toggle}>
                <CaretLeftOutlined />
            </div>
        </>}
    </ContainerDiv>;
};

