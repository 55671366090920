import { whenOnce } from "@arcgis/core/core/watchUtils";
import MapView from "@arcgis/core/views/MapView";
import { useContext, useEffect, useRef } from "react";
import { MapContext } from "../../../../App/MapContextContainer";
import { DECLARATIONS_MIN_SCALE_TO_SHOW } from "../../../../App/useNotificationsLayer";
import * as projection from "@arcgis/core/geometry/projection";
import Extent from "@arcgis/core/geometry/Extent";
import WFSLayer from "@arcgis/core/layers/WFSLayer";
import GeoJSONLayer from "@arcgis/core/layers/GeoJSONLayer";
import * as promiseUtils from "@arcgis/core/core/promiseUtils";
import { PREFERRED_WKID } from "../../../utils";

const preferredSpatialReference = {
    wkid: PREFERRED_WKID
};

const useBboxUpdate = (view: MapView, layer: WFSLayer | GeoJSONLayer, customParameters: any, customCQLString: string = "1 = 1", geometryPropertyName: string = "geom", scaleToShow: number = DECLARATIONS_MIN_SCALE_TO_SHOW) => {
    const viewWatcher = useRef<__esri.WatchHandle | null>(null);
    const mapContext = useContext(MapContext);
    const defaultCustomCQL = "1 = 1";

    const updateBBOX = promiseUtils.debounce(() => {
        return new Promise((resolve) => {
            let geometryInPreferredWkid = projection.project(view.extent, preferredSpatialReference) as Extent;

            const {
                xmin,
                ymin,
                xmax,
                ymax,
                spatialReference: { wkid }
            } = geometryInPreferredWkid;

            if (layer.type === "geojson") {
                layer.customParameters = {
                    ...customParameters,
                    "bbox": `${xmin},${ymin},${xmax},${ymax}`
                };
            }
            else if (layer.type === "wfs") {
                layer.customParameters = {
                    ...customParameters,
                    cql_filter: `BBOX(${geometryPropertyName},${xmin},${ymin},${xmax},${ymax},'EPSG:4326') AND ${customCQLString || defaultCustomCQL}`
                };
            }

            layer.refresh();

            whenOnce(layer, "refresh", resolve);
        });
    });

    useEffect(() => {
        if (viewWatcher.current !== null) {
            viewWatcher.current.remove();
        }

        viewWatcher.current = view.watch("stationary", (stationary) => {
            if (stationary && view.scale <= scaleToShow) {
                updateBBOX().catch();
            }
        });

        return () => {
            if (viewWatcher.current !== null) {
                viewWatcher.current.remove();
            }
        }
    }, [mapContext.mapAndViewReady, customCQLString]);

    useEffect(() => {
        if (view.scale <= scaleToShow) {
            updateBBOX().catch();
        }
    }, [customCQLString, view.scale]);
}

export default useBboxUpdate;