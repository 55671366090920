import { Button, Form, Input, Typography } from 'antd'
import TextArea from 'antd/lib/input/TextArea';
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const CaptchaAndButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`;

export default function CommentForm(): ReactElement {
    const [hasErrors, setHasErrors] = useState(true);
    const [commentSentOk, setCommentSentOk] = useState<boolean | null>(null);
    const [form] = Form.useForm();
    const ref = useRef(null);
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation("feedbackForm");

    const validateMessages = {
        required: t("syötäKommentti")
    };

    // Create an event handler so you can call the verification on button click event or form submit
    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }

        return await executeRecaptcha('orderForm');
        // Do whatever you want with the token
    }, []);

    // You can use useEffect to trigger the verification as soon as the component being loaded
    useEffect(() => {
        handleReCaptchaVerify();
    }, [handleReCaptchaVerify]);

    const sendComment = (formValues: any) => {
        const realCall = async () => {
            try {
                setIsLoading(true);

                const token = await handleReCaptchaVerify();

                const res = await fetch(`${process.env.REACT_APP_API_URL}/api/AddComment`, {
                    body: JSON.stringify({ captcha: token, comment: formValues.comment + " " + formValues.email }),
                    method: "POST"
                });

                if (res.ok) {
                    setCommentSentOk(true);

                    form.resetFields();
                }
                else {
                    setCommentSentOk(false);

                    try {
                        (ref.current as any).reset();
                    } catch (error) {
                        console.log("Failed to reset captcha:" + error);
                    }
                }
            } catch (error) {
                setCommentSentOk(false);

                try {
                    (ref.current as any).reset();
                } catch (error) {
                    console.log("Failed to reset captcha:" + error);
                }
            }
            finally {
                setIsLoading(false);
            }
        };

        realCall();
    }

    const handleFieldsChange = (state: any) => {
        if (state[0].errors.length > 0) {
            setHasErrors(true);
        }
        else {
            setHasErrors(false);
        }

        setCommentSentOk(null);
    };

    return (
        <div style={{ "maxWidth": "600px" }}>
            <p>
                <span>{t("palauteTervetullutta")}</span>
            </p>
            <p>
                <span>{t("palautettaMyosX")}</span>
            </p>

            {commentSentOk === false && <div>
                <Typography.Title type="warning" level={5}>{t("virheviesti")}</Typography.Title>
            </div>}

            {commentSentOk === true && <div>
                <Typography.Title type="success" level={5}>{t("kiitosviesti")}</Typography.Title>
            </div>}

            <Form form={form} validateMessages={validateMessages} onFinish={sendComment} onFieldsChange={handleFieldsChange} >
                <Form.Item
                    name={['email']}
                    label={t("emailOtsikko")}
                    rules={[
                        {
                            required: false
                        },
                    ]}
                >
                    <Input size={"large"} maxLength={200} />
                </Form.Item>
                <Form.Item
                    name={['comment']}
                    label={t("kentänOtsikko")}
                    rules={[
                        {
                            required: true
                        },
                    ]}
                >
                    <TextArea size={"large"} maxLength={3000} />
                </Form.Item>

                <CaptchaAndButtonsContainer>
                    <Button loading={isLoading} type="primary" htmlType="submit" disabled={hasErrors}>{t("nappula")}</Button>
                </CaptchaAndButtonsContainer>
            </Form>
        </div>
    )
}
