import Input from 'antd/lib/input/Input';
import React, { useContext } from 'react'
import { MapContext } from '../../../../App/MapContextContainer';
import { UserDataContext } from '../../UserDataContextContainer';
import WizardStep from '../../../../components/Wizard/WizardStep';

export default function NewUserAreaWizard() {
    const userDataContext = useContext(UserDataContext);
    const mapContext = useContext(MapContext);

    const save = async (): Promise<boolean> => {
        await userDataContext.saveNewArea("");

        return false;
    }

    const startDrawingOnMap = async (): Promise<boolean> => {
        mapContext.setIsSketching(true);

        return true;
    }

    return (<>
        <WizardStep step={0} nextButtonAction={startDrawingOnMap}>
            <>
                <p>Siirrä ja loitonna/lähennä karttaa niin, että näet koko sen alueen, jonka haluat merkitä. Paina sitten Seuraava-nappia.</p>
            </>
        </WizardStep>
        <WizardStep step={1} canMoveToNext={userDataContext.newArea?.geometry !== undefined && userDataContext.newArea !== null}>
            <>
                <p>Piirrä haluamasi alue kartalle.</p>
                <p>Klikkaa hiirellä kartalla haluamasi alueen nurkkaan, pidä hiiren nappula edelleen pohjassa ja liikuta hiirtä, jolloin kartalle syntyy suorakulmio.</p>
                <p>Voit myös klikata hiirellä piirtämääsi aluetta, jonka jälkeen voit siirtää aluetta eri paikkaan, tai ottaa hiirellä kiinni pienistä neliöistä alueen reunoilla ja muuttaa alueen kokoa. Klikkaa muutosten jälkeen
                    vielä hiirellä kartalla johonkin alueen ulkopuolelle, jolloin muutokset tulevat voimaan.
                </p>
            </>
        </WizardStep>
        <WizardStep step={2} nextButtonAction={save} nextButtonTitle={"Tallenna"} canMoveToNext={userDataContext.newArea?.name !== undefined && userDataContext.newArea?.name !== ""}><>
            <p>Anna alueelle nimi ja tallenna</p>
            <Input id="areaName" type={'text'}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => userDataContext.newUserAreaDispatch({ type: "setName", name: e.target.value })}
                value={userDataContext.newArea?.name || ""}></Input></>
        </WizardStep>
    </>)
}
