import LayerList from "@arcgis/core/widgets/LayerList";
import { useRef } from "react";

export default function useSetUpLayerListActions(layerList: LayerList) {
  const isListening = useRef(false);

  layerList.listItemCreatedFunction = async (event: any) => {
    const item = event.item;

    await item.layer.when();

    item.actionsSections = [
      [
        {
          title: "Lisää läpinäkyvyyttä",
          className: "esri-icon-up",
          id: "increase-opacity"
        },
        {
          title: "Vähennä läpinäkyvyyttä",
          className: "esri-icon-down",
          id: "decrease-opacity"
        }
      ]
    ];
  }

  if (!isListening.current) {
    isListening.current = true;

    layerList.on("trigger-action", (event) => {
      const id = event.action.id;

      if (id === "increase-opacity") {
        event.item.layer.opacity -= 0.25;
      } else if (id === "decrease-opacity") {
        event.item.layer.opacity += 0.25;
      }
    });
  }

  return;
}
