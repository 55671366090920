import { useAccount, useMsal } from "@azure/msal-react";
import { protectedResources } from "../authConfig";

export function useAuthFetch() {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || undefined);

    const authFetch = async (input: RequestInfo, init?: RequestInit): Promise<Response> => {
        const tokenResponse = await instance.acquireTokenSilent({
            scopes: protectedResources.api.scopes,
            account: account || undefined
        });

        const subClaim = account?.localAccountId;
        let inputToUse = input;

        if (typeof (input) === "string" && subClaim) {
            inputToUse = (input as string).replace("<sub>", subClaim);
        }

        const headers = new Headers();
        const bearer = `Bearer ${tokenResponse.accessToken}`;

        headers.append("Authorization", bearer);

        const initWithHeaders = { ...init, headers };

        return await fetch(inputToUse, initWithHeaders);
    }

    return [authFetch];
}

