import React, { createContext, Dispatch, ReactElement, useContext, useEffect, useReducer, useState } from 'react'
import { useIsAuthenticated } from '@azure/msal-react';
import { useAuthFetch } from '../../App/useAuthFetch';
import { MapContext } from '../../App/MapContextContainer';
import Polygon from '@arcgis/core/geometry/Polygon';
import Graphic from '@arcgis/core/Graphic';
import * as projection from "@arcgis/core/geometry/projection";
import { convertUserAreaFromAPI } from './Utils';
import { PREFERRED_WKID } from '../utils';

export interface IUserArea {
  id: string,
  name: string,
  geometry: Polygon,
  center: number[],
  spatialReference: number
}

export interface IUserDataContext {
  areas: IUserArea[],
  newArea: IUserArea | null,
  removeArea: (area: IUserArea) => void,
  newUserAreaDispatch: Dispatch<NewUserAction>,
  saveNewArea: (areaName: string) => Promise<boolean>,
  cancelAddingNewArea: () => void,
  isAddingNew: boolean,
  setIsAddingNew: (isAddingNew: boolean) => void
}

interface IProps {
  children: ReactElement;
}

export const UserDataContext = createContext<IUserDataContext>({} as IUserDataContext);

type NewUserAction =
  | { type: 'setName', name: string }
  | { type: 'setGeography', geometry: Polygon }
  | { type: "reset" };

function newUserAreaReducer(state: IUserArea, action: NewUserAction): IUserArea {
  switch (action.type) {
    case 'setName':
      return { ...state, name: action.name };
    case 'setGeography':
      return { ...state, geometry: action.geometry };
    case 'reset':
      return { ...initialState }
    default:
      throw new Error();
  }
}

const initialState = {} as IUserArea;

export default function UserDataContextContainer(props: IProps) {
  const [areas, setAreas] = useState<IUserArea[]>([]);
  const isAuthenticated = useIsAuthenticated();
  const [authFetch] = useAuthFetch();
  const [isAddingNewState, setIsAddingNewState] = useState(false);
  const [newArea, newUserAreaDispatch] = useReducer(newUserAreaReducer, initialState);
  const mapContext = useContext(MapContext);
  const [allClashes, setAllClashes] = useState<Graphic[] | undefined>();

  const cancelAddingNewArea = () => {
    newUserAreaDispatch({ type: "reset" });

    setIsAddingNew(false);
    mapContext.setIsSketching(false);

    setAreas([...areas]);
  };

  const setIsAddingNew = (isAddingNew: boolean) => {
    setIsAddingNewState(isAddingNew);
  };

  const saveNewArea = async (areaName: string): Promise<boolean> => {
    if (projection.isLoaded() === false) {
      await projection.load();
    }

    let outSpatialReference = {
      wkid: PREFERRED_WKID
    };

    const geometryInPreferredWkid = projection.project(newArea.geometry, outSpatialReference) as Polygon;

    const response = await authFetch(`${process.env.REACT_APP_API_URL}/api/user/<sub>/area`, {
      method: "POST",
      body: JSON.stringify({ ...newArea, geometry: geometryInPreferredWkid.rings[0], spatialReference: PREFERRED_WKID })
    });

    const newAreaWithId = await response.json();
    const newAreaWithConvertedGraphics = convertUserAreaFromAPI(newAreaWithId);

    setAreas(areas.concat([newAreaWithConvertedGraphics]));

    setIsAddingNew(false);
    mapContext.setIsSketching(false);

    newUserAreaDispatch({ type: "reset" });

    return true;
  };

  const removeArea = async (areaToRemove: IUserArea) => {
    await authFetch(`${process.env.REACT_APP_API_URL}/api/user/<sub>/area/${areaToRemove.id}`, {
      method: "DELETE"
    });

    const remainingAreas = areas.filter(area => {
      return area.id !== areaToRemove.id;
    });

    setAreas(remainingAreas);
  };

  useEffect(() => {
    const asyncCall = async () => {
      const res = await authFetch(`${process.env.REACT_APP_API_URL}/api/user/<sub>/area`, {
        method: "GET"
      });

      // Just in case, if we get the areas late we might mess up the process if we are already adding new.
      setIsAddingNew(false);

      const asJson = await res.json() as any[];
      const asPolygonGraphics: IUserArea[] = asJson.map(userArea => {
        return convertUserAreaFromAPI(userArea)
      });

      setAreas(asPolygonGraphics);
    }

    if (isAuthenticated) {
      asyncCall();
    }
  }, [isAuthenticated]);

  return (
    <UserDataContext.Provider value={{
      areas,
      newArea,
      removeArea,
      saveNewArea,
      newUserAreaDispatch,
      cancelAddingNewArea,
      isAddingNew: isAddingNewState,
      setIsAddingNew
    }}>
      {props.children}
    </UserDataContext.Provider>
  );
}
