import PopupTemplate from "@arcgis/core/PopupTemplate";
import WebMap from "@arcgis/core/WebMap";
import { useContext, useEffect, useMemo, useRef } from "react";
import { AppSettingsContext } from "./AppSettingsContextContainer";
import { MapContext } from "./MapContextContainer";
import useSetDeclarationPopupContent from "./useSetDeclarationPopupContent";
import WFSLayer from "@arcgis/core/layers/WFSLayer";
import notificationsRenderer from "../components/Maps/NotificationsRenderer";
import * as promiseUtils from "@arcgis/core/core/promiseUtils";
import * as projection from "@arcgis/core/geometry/projection";
import { whenOnce } from "@arcgis/core/core/watchUtils";
import MapView from "@arcgis/core/views/MapView";
import Extent from "@arcgis/core/geometry/Extent";
import { PREFERRED_WKID, getFromAndToDatesFromMonths, numberToPastDate } from "../pages/utils";
import useTestLayer from "./useTestLayer";
import { useTranslation } from "react-i18next";
import errors from "./errors";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

export const DECLARATIONS_MIN_SCALE_TO_SHOW = 75000;
export const DECLARATIONS_LAYER_ID = "forestUseNotifications";

export const oldDeclarationsApiUrl = "https://rajapinnat.metsaan.fi/geoserver/Avoinmetsatieto/forestusedeclaration/ows";
export const newDeclarationsApiUrl = "https://avoin.metsakeskus.fi/rajapinnat/v1/forestusedeclaration/ows";

export function useNotificationsLayer(webmap: WebMap, view: MapView, notificationsFrom: Date | undefined = undefined, showByDefault = true) {
    const mapContext = useContext(MapContext);
    const setPopupContent = useSetDeclarationPopupContent();
    const { appliedFilters } = useContext(AppSettingsContext);
    const viewWatcher = useRef<__esri.WatchHandle | null>(null);
    const { t, i18n } = useTranslation("common");

    const betaArcgisLayer = useMemo(() => new FeatureLayer({
        url: "https://aineistot.metsakeskus.fi/metsakeskus/rest/services/Avoin_mets%C3%A4tieto/MKI_KUVIO_beta/MapServer/0",
        outFields: ["SAAPUMISPVM", "OBJECTID", "IKA", "KUVIONUMERO", "TILA", "MKINUMERO", "TOTEUTTAMISVUOSI", "PINTA_ALA", "HAKKUUNTOTEUTTAMISTAPA", "ERITYISENTARKEAELINYMP", "ERITYISENTARKEAELINYMPTARK", "MUULUONTOKOHDE"],
        title: t("mkiKerrosOtsikko") + " BETA!",
        listMode: "show",
        visible: false,
        copyright: "Suomen metsäkeskus"
    }), []);

    const ows = useMemo(() => new WFSLayer({
        url: process.env.REACT_APP_USE_OLD_DECLARATION_URL === "true" ? oldDeclarationsApiUrl : newDeclarationsApiUrl,
        title: t("mkiKerrosOtsikko"),
        id: DECLARATIONS_LAYER_ID,
        visible: showByDefault,
        copyright: "Suomen metsäkeskus",
        legendEnabled: false,
        minScale: DECLARATIONS_MIN_SCALE_TO_SHOW,
        spatialReference: { wkid: 4326 },
        renderer: notificationsRenderer,
        customParameters: {
            propertyName: `(DECLARATIONARRIVALDATE,AREA,CUTTINGREALIZATIONPRACTICE,GEOMETRY,MEANAGE,DECLARATIONSTATE,HABITATCODE,DETAILEDHABITATCODE,OTHERHABITATCODE,HABITATOPERATION,STANDNUMBER)`,
            cql_filter: "1 = 0"
        }
    }), []);

    useEffect(() => {
        const layer = webmap.findLayerById(ows.id);

        if (layer) {
            layer.title = t("mkiKerrosOtsikko");
        }

        const layerBeta = webmap.findLayerById(betaArcgisLayer.id);

        if (layerBeta) {
            layerBeta.title = t("mkiKerrosOtsikko") + (i18n.language === "en" ? " (old system)" : " (vanha järjestelmä)");
        }
    }, [i18n.language, t]);

    useTestLayer((process.env.REACT_APP_USE_OLD_DECLARATION_URL === "true" ? oldDeclarationsApiUrl : newDeclarationsApiUrl) + "?SERVICE=WFS&REQUEST=GetCapabilities&VERSION=2.0.0&format=json", errors.notificationLayerLoadError);

    let preferredSpatialReference = {
        wkid: PREFERRED_WKID
    };

    const updateBBOX = promiseUtils.debounce(() => {
        return new Promise((resolve) => {
            const cqlFilterCriteria = getCqlFilterCriteria();

            let geometryInPreferredWkid = projection.project(view.extent, preferredSpatialReference) as Extent;
            geometryInPreferredWkid = geometryInPreferredWkid.expand(1.3);

            const {
                xmin,
                ymin,
                xmax,
                ymax,
                spatialReference: { wkid }
            } = geometryInPreferredWkid;

            ows.customParameters = {
                propertyName: `(FORESTUSEDECLARATIONNUMBER,DECLARATIONARRIVALDATE,AREA,CUTTINGREALIZATIONPRACTICE,GEOMETRY,MEANAGE,DECLARATIONSTATE,HABITATCODE,DETAILEDHABITATCODE,OTHERHABITATCODE,HABITATOPERATION,STANDNUMBER)`,
                cql_filter:
                    cqlFilterCriteria +
                    ` AND ` +
                    `BBOX(GEOMETRY,${xmin},${ymin},${xmax},${ymax},'EPSG:4326')`
            };

            ows.refresh();

            whenOnce(ows, "refresh", resolve);
        });
    });

    useEffect(() => {
        if (mapContext.mapAndViewReady === false) {
            return;
        }

        if (viewWatcher.current !== null) {
            viewWatcher.current.remove();
        }

        viewWatcher.current = view.watch("stationary", (stationary) => {
            if (stationary && view.scale > 0 && view.scale <= DECLARATIONS_MIN_SCALE_TO_SHOW) {
                updateBBOX().catch();
            }
        });

        return () => {
            if (viewWatcher.current !== null) {
                viewWatcher.current.remove();
            }
        }
    }, [mapContext.mapAndViewReady, appliedFilters]);

    useEffect(() => {
        if (mapContext.mapAndViewReady === false) {
            return;
        }

        if (view.scale > 0 && view.scale <= DECLARATIONS_MIN_SCALE_TO_SHOW) {
            updateBBOX().catch();
        }
    }, [appliedFilters, mapContext.mapAndViewReady]);

    useEffect(() => {
        // Legend data is needed for the popup to work, so don't proceed setting the popup etc before we have the data.
        if (mapContext.legendData === null || mapContext.mapAndViewReady === false) {
            return;
        }

        const notificationsLayerPopup = new PopupTemplate({
            title: t("mkiPopupOtsikko"),
            outFields: ["FORESTUSEDECLARATIONNUMBER", "DECLARATIONARRIVALDATE", "MEANAGE", "CUTTINGREALIZATIONPRACTICE", "AREA", "DECLARATIONSTATE", "HABITATCODE", "DETAILEDHABITATCODE", "OTHERHABITATCODE", "HABITATOPERATION", "STANDNUMBER"],
            content: setPopupContent
        });

        const betaNotificationsLayerPopup = new PopupTemplate({
            title: t("mkiPopupOtsikko"),
            outFields: ["SAAPUMISPVM", "OBJECTID", "IKA", "KUVIONUMERO", "TILA", "MKINUMERO", "TOTEUTTAMISVUOSI", "PINTA_ALA", "HAKKUUNTOTEUTTAMISTAPA", "ERITYISENTARKEAELINYMP", "ERITYISENTARKEAELINYMPTARK", "MUULUONTOKOHDE"],
            content: setPopupContent
        });

        ows.popupTemplate = notificationsLayerPopup;
        betaArcgisLayer.popupTemplate = betaNotificationsLayerPopup;
    }, [i18n.language, t, mapContext.mapAndViewReady]);

    useEffect(() => {
        webmap.add(ows);
        webmap.add(betaArcgisLayer);
    }, [mapContext.mapAndViewReady]);

    const getCqlFilterCriteria = () => {
        let filterString = ""; //"TILA IN (10, 20, 31, 33, 40, 41)";

        if (appliedFilters?.declarationArrivalMonths && appliedFilters?.declarationArrivalMonths.length === 2) {
            filterString += filterString ? " AND " : "";

            const { fromDate, toDate } = getFromAndToDatesFromMonths(appliedFilters.declarationArrivalMonths);

            if (fromDate && toDate) {
                filterString = filterString + `DECLARATIONARRIVALDATE AFTER ${fromDate.getFullYear()}-${(fromDate.getMonth() + 1 + "").padStart(2, "0")}-01T00:00:00Z 
                    AND DECLARATIONARRIVALDATE BEFORE ${toDate.getFullYear()}-${(toDate.getMonth() + 1 + "").padStart(2, "0")}-${toDate.getDate()}T00:00:00Z`;
            }
        }
        else if (notificationsFrom) {
            filterString = filterString + `DECLARATIONARRIVALDATE AFTER ${notificationsFrom.getFullYear()}-${(notificationsFrom.getMonth() + 1 + "").padStart(2, "0")}-01T00:00:00Z`;
        }
        else {
            const threeYearsAgo = numberToPastDate(-36);

            if (threeYearsAgo === undefined) {
                throw ("Failed to get date past three years ago, strange!");
            }

            if (threeYearsAgo) {
                filterString = filterString + `DECLARATIONARRIVALDATE AFTER ${threeYearsAgo.getFullYear()}-${(threeYearsAgo.getMonth() + 1 + "").padStart(2, "0")}-01T00:00:00Z`;
            }
        }

        if (appliedFilters?.cuttingRealizationPractices && appliedFilters?.cuttingRealizationPractices.length > 0) {
            filterString += filterString ? " AND " : "";

            filterString = filterString + `${"CUTTINGREALIZATIONPRACTICE"} IN (${appliedFilters.cuttingRealizationPractices})`;
        }

        if (appliedFilters?.ERITYINENELINYMPARISTO) {
            filterString += filterString ? " AND " : "";

            filterString = filterString + `(HABITATCODE > 0 OR DETAILEDHABITATCODE > 0 OR OTHERHABITATCODE > 0)`;
        }

        // if (appliedFilters?.TILA && appliedFilters?.TILA.length > 0) {
        //     filterString = filterString + `${"TILA"} IN (${appliedFilters.TILA.map(t => `'${t}'`)})`;
        // }

        if (appliedFilters?.IKA && appliedFilters?.IKA.length === 2 && (appliedFilters.IKA[0] > 0 || appliedFilters.IKA[1] < 200)) {
            filterString += filterString ? " AND " : "";

            filterString = filterString + `MEANAGE >= ${appliedFilters.IKA[0]} AND MEANAGE <= ${appliedFilters.IKA[1]}`;
        }

        return filterString;
    };

    useEffect(() => {
        let filterString = "";

        if (appliedFilters?.declarationArrivalMonths && appliedFilters?.declarationArrivalMonths.length === 2) {
            filterString += filterString ? " AND " : "";

            const { fromDate, toDate } = getFromAndToDatesFromMonths(appliedFilters.declarationArrivalMonths);

            if (fromDate && toDate) {
                filterString = filterString + `SAAPUMISPVM >= DATE '${fromDate.getFullYear()}-${(fromDate.getMonth() + 1 + "")}-1' 
                    AND SAAPUMISPVM < DATE '${toDate.getFullYear()}-${(toDate.getMonth() + 1 + "")}-${toDate.getDate()}'`;
            }
        }
        else if (notificationsFrom) {
            filterString = filterString + `SAAPUMISPVM >= DATE '${notificationsFrom.getFullYear()}-${(notificationsFrom.getMonth() + 1 + "")}-1'`;
        }
        else {
            const threeYearsAgo = numberToPastDate(-36);

            if (threeYearsAgo === undefined) {
                throw ("Failed to get date past three years ago, strange!");
            }

            if (threeYearsAgo) {
                filterString = filterString + `SAAPUMISPVM >= DATE '${threeYearsAgo.getFullYear()}-${(threeYearsAgo.getMonth() + 1 + "")}-1'`;
            }
        }

        if (appliedFilters?.cuttingRealizationPractices && appliedFilters?.cuttingRealizationPractices.length > 0) {
            filterString += filterString ? " AND " : "";

            filterString = filterString + `${"HAKKUUNTOTEUTTAMISTAPA"} IN (${appliedFilters.cuttingRealizationPractices})`;
        }

        if (appliedFilters?.ERITYINENELINYMPARISTO) {
            filterString += filterString ? " AND " : "";

            filterString = filterString + `(ERITYISENTARKEAELINYMP > 0 OR ERITYISENTARKEAELINYMPTARK > 0 OR MUULUONTOKOHDE > 0)`;
        }

        if (appliedFilters?.IKA && appliedFilters?.IKA.length === 2 && (appliedFilters.IKA[0] > 0 || appliedFilters.IKA[1] < 200)) {
            filterString += filterString ? " AND " : "";

            filterString = filterString + `IKA >= ${appliedFilters.IKA[0]} AND IKA <= ${appliedFilters.IKA[1]}`;
        }

        betaArcgisLayer.definitionExpression = filterString;
    }, [appliedFilters]);

    return { ows, betaArcgisLayer };
}
