import Polygon from "@arcgis/core/geometry/Polygon"
import WFSLayer from "@arcgis/core/layers/WFSLayer"
import WebMap from "@arcgis/core/WebMap"
import { DECLARATIONS_LAYER_ID } from "../../App/useNotificationsLayer"

export const convertUserAreaFromAPI = function (userArea: any) {
    return {
        ...userArea,
        geometry: new Polygon({
            rings: [userArea.geometry],
            spatialReference: { wkid: userArea.spatialReference }
        })
    }
}

export const getNotificationsLayer = function (webmap: WebMap) {
    const notificationLayer = webmap.findLayerById(DECLARATIONS_LAYER_ID) as WFSLayer;

    return notificationLayer;
}