import { createGlobalStyle } from 'styled-components';

interface GlobalStylesProps {
	spinnerBackgroundColor: string
}

const GlobalStyles = createGlobalStyle<GlobalStylesProps>` 
	html {
		overflow-x: hidden;
		overflow-y: scroll;
		text-rendering: optimizeLegibility;
		height: 100vh;

		&.mobile-menu-open {
			overflow: hidden;
		}
	}

	html,
	body {
		// NOTE: global style in antd4
		color: rgba(0, 0, 0, 0.88);
		font-size: 16px;
		line-height: 1.5714285714285714;
		font-family: "Roboto Condensed", sans-serif !important;
		/* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'; */
		box-sizing: border-box;
		overflow-y: hidden;
	}

	body {		
		-webkit-font-smoothing: antialiased;
  		-moz-osx-font-smoothing: grayscale;
	}

	.ant-spin-dot-item {
 		background-color: ${p => p.spinnerBackgroundColor};
	}

	.grecaptcha-badge { 
    	visibility: hidden;
	}

	.hide-esri-default-action-icon {
  		margin-left: -16px !important;
	}
`;

export default GlobalStyles;