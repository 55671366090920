import React, { useContext } from 'react'
import styled from 'styled-components';
import { AppSettingsContext } from '../../App/AppSettingsContextContainer';

interface ITopMenuContainerProps {
    backgroundColor: string
    borderColor: string
}

const TopMenuContainer = styled.div<ITopMenuContainerProps>`
    height: 4em; 
    min-height: 4em;
    background-color: ${props => props.backgroundColor};
    border-bottom: 5px solid;
    border-color: ${props => props.borderColor};    
`;

export default function TopMenuSuspended() {
    const appSettingsContext = useContext(AppSettingsContext);

    return (
        <TopMenuContainer backgroundColor={appSettingsContext.colors[3]} borderColor={appSettingsContext.colors[5]}></TopMenuContainer>
    )
}