import React, { createContext, ReactElement, useEffect, useState } from 'react'
import { generate } from "@ant-design/colors";

export interface IAppliedFilters {
  IKA: number[],
  declarationArrivalMonths: number[],
  cuttingRealizationPractices: string[],
  TILA: string[],
  ERITYINENELINYMPARISTO: string
}

export interface IAppSettingsContext {
  colors: string[],
  isMobile: boolean,
  isMobileBottomPanelOpen?: boolean,
  setIsMobileBottomPanelOpen: (isOpen: boolean) => void,
  appliedFilters?: IAppliedFilters,
  setAppliedFilters: (filters?: IAppliedFilters) => void
}

interface IProps {
  children: ReactElement;
}

export const AppSettingsContext = createContext<IAppSettingsContext>({} as IAppSettingsContext);

const AppSettingsContextContainer = (props: IProps) => {
  const colors = generate("#daf0c4");
  const isMobile = window.innerWidth < 1080;
  const [isMobileBottomPanelOpen, setIsMobileBottomPanelOpen] = useState<boolean | undefined>(isMobile || undefined);
  const [appliedFilters, setAppliedFilters] = useState<IAppliedFilters | undefined>();

  const setAppliedFiltersFn = (filters?: IAppliedFilters) => {
    setAppliedFilters(filters ? { ...filters } : undefined);

    if (filters === undefined) {
      localStorage.removeItem("appliedFilters");
    }
    else {
      localStorage.setItem("appliedFilters", JSON.stringify(filters));
    }
  };

  const setIsMobileBottomPanelOpenFn = (isOpen: boolean) => {
    setIsMobileBottomPanelOpen(isOpen);
  };

  useEffect(() => {
    const appliedFilters = localStorage.getItem("appliedFilters");

    if (appliedFilters) {
      setAppliedFilters(JSON.parse(appliedFilters));
    }
  }, []);

  return (
    <AppSettingsContext.Provider value={{
      colors, isMobile, isMobileBottomPanelOpen,
      setIsMobileBottomPanelOpen: setIsMobileBottomPanelOpenFn,
      setAppliedFilters: setAppliedFiltersFn,
      appliedFilters
    }}>
      {props.children}
    </AppSettingsContext.Provider >
  );
}

export default AppSettingsContextContainer;