import React, { useContext, useEffect } from 'react'
import { PageContext } from './PageContextContainer';
import { IError } from './errors';

export default function useTestLayer(url: string, errorToUse: IError) {
    const pageContext = useContext(PageContext);

    useEffect(() => {
        const asyncCall = async () => {
            try {
                const response = await fetch(url);

                // TODO: For XML GetCapabilities request we really should test this better. Even an error response will go through as it is HTTP 200...

                if (response && response.status === 200) {
                    // All good
                }
                else {
                    pageContext.setMapError(errorToUse);
                }
            } catch (error) {
                pageContext.setMapError(errorToUse);
            }
        }

        asyncCall();
    }, []);
}