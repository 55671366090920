import { Button, Typography } from 'antd';
import React, { ReactElement, useContext } from 'react'
import styled from 'styled-components';
import { AppSettingsContext } from '../../App/AppSettingsContextContainer';
import { WizardContext } from './Wizard';

interface IProps {
    children: ReactElement,
    step: number,
    nextButtonAction?: () => Promise<boolean>,
    nextButtonTitle?: string,
    canMoveToNext?: boolean
}

interface IWizardStepContainerProps {
    isMobile: boolean,
    isCurrent: boolean
}

const WizardStepContainer = styled.div<IWizardStepContainerProps>`
    display: ${props => props.isCurrent ? "block" : "none"};
`;

const ButtonsContainer = styled.div`
    margin-top: 1em;
    display: flex;
    justify-content: space-between;
`;

export default function WizardStep(props: IProps) {
    const wizardContext = useContext(WizardContext);
    const appSettingsContext = useContext(AppSettingsContext);

    return (<WizardStepContainer isCurrent={wizardContext.step === props.step} isMobile={appSettingsContext.isMobile}>
        <Typography.Title level={5}>{wizardContext.step + 1}.</Typography.Title>
        {props.children}

        <ButtonsContainer>
            <Button type='primary' disabled={wizardContext.step === 0} onClick={() => wizardContext.goToPreviousStep()}>Edellinen</Button>
            <Button type='primary' disabled={props.canMoveToNext !== undefined && props.canMoveToNext === false} onClick={async () => (props.nextButtonAction ? await props.nextButtonAction() : true) && wizardContext.goToNextStep()}>{props.nextButtonTitle ?? "Seuraava"}</Button>
        </ButtonsContainer>
    </WizardStepContainer>
    )
}