import React, { useCallback, useContext } from 'react'
import styled from 'styled-components'
import { AppSettingsContext } from '../../App/AppSettingsContextContainer';
import {
    ExclamationCircleOutlined,
    CloseOutlined
} from '@ant-design/icons';
import { Button } from 'antd';
import { IError } from '../../App/errors';
import { PageContext } from '../../App/PageContextContainer';
import { useTranslation } from 'react-i18next';

interface IErrorsContainerProps {
    isMobile: boolean
}

const ErrorsContainer = styled.div<IErrorsContainerProps>`
    position: absolute;
    left: 10px;
    top: 20px;

    right: ${p => p.isMobile ? "10px" : ""};

    display: flex;
    flex-direction: column;
`;

interface IErrorMessageContainerProps {
    bgColor: string
}

const ErrorMessageContainer = styled.div<IErrorMessageContainerProps>`
    margin-bottom: 5px;
    background-color: ${props => props.bgColor};
    padding: 1em;
    border: 1px black solid;

    display: flex;
    align-items: center;
`;

const CloseButtonContainer = styled.div`
    margin-left: auto;
`;

interface IProps {
    error: IError
}

export function ErrorMessageContainerComponent(props: IProps) {
    const { colors } = useContext(AppSettingsContext);
    const pageContext = useContext(PageContext);
    const { t } = useTranslation("common");

    const onCloseMessageClick = (error: IError) => {
        pageContext.clearMapError(error);
    };

    const getMessageToShow = useCallback(() => {
        let baseText = t(props.error.translationKey);

        if (props.error.replacementValues) {
            baseText = baseText.replace("<-->", props.error.replacementValues[0]);
        }

        return baseText;
    }, [props.error.translationKey, t]);

    return <ErrorMessageContainer bgColor={colors[2]}>
        <ExclamationCircleOutlined style={{ fontSize: "125%", marginRight: "5px" }} /> {getMessageToShow()}
        {props.error.level === 'dismissable' &&
            <CloseButtonContainer><Button size='small' onClick={() => onCloseMessageClick(props.error)}><CloseOutlined /></Button></CloseButtonContainer>
        }
    </ErrorMessageContainer>
}

export default function MapErrorContainer() {
    const pageContext = useContext(PageContext);
    const { isMobile } = useContext(AppSettingsContext);

    return (<ErrorsContainer isMobile={isMobile}>
        {(pageContext?.mapErrors || []).map(error => {
            return <ErrorMessageContainerComponent error={error} key={
                error.translationKey.concat(error.replacementValues ? error.replacementValues?.join("") : "")} />
        })}
    </ErrorsContainer>)
}