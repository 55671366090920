interface ILoadingNotifications {
    loadingHabitatsGrid: ILoadingNotification,
    loadingHabitatsClashes: ILoadingNotification,
    loadingObservationsGrid: ILoadingNotification,
    loadingObservationClashes: ILoadingNotification,
    loadingCollectionMaps: ILoadingNotification,
    loadingCodeSets: ILoadingNotification,
    loadingAllLajiFiObservations: ILoadingNotification
}

const loadingNotifications: ILoadingNotifications = {
    loadingHabitatsGrid: {
        translationKey: "loadingHabitatInformation"
    },
    loadingHabitatsClashes: {
        translationKey: "loadingHabitatsClashes"
    },
    loadingObservationClashes: {
        translationKey: "loadingObservationClashes"
    },
    loadingObservationsGrid: {
        translationKey: "loadingObservationsGrid"
    },
    loadingCollectionMaps: {
        translationKey: "loadingCollectionMaps"
    },
    loadingCodeSets: {
        translationKey: "loadingCodeSets"
    },
    loadingAllLajiFiObservations: {
        translationKey: "loadingAllLajiFiObservationsForTheArea"
    }
}

export interface ILoadingNotification {
    translationKey: string
}

export default loadingNotifications;