import { ReactElement } from 'react'
import { Collapse } from 'antd';
import { IMenuContentProps } from '../../../../components/MapMenu/MapMenu';
import {
	InfoOutlined
  } from '@ant-design/icons';
const { Panel } = Collapse;

export default function MobileMenuContents(props: IMenuContentProps): ReactElement {
	return (
		<div>
			<Collapse accordion={true} defaultActiveKey={"1"}>
				<Panel header="Omat alueet" key="1" extra={<InfoOutlined></InfoOutlined>}>
					<b>Tämä ominaisuus on tulossa pian!</b>
					<p>
						<span>Tällä kartalla voit määritellä omia alueitasi, joihin osuvista metsänkäyttöilmoituksista saat ilmoituksen sähköpostiisi.</span>
					</p>
					<p>
						<span>Kartalla näet myös metsänkäyttöilmoitukset. Voit valita, mitä metsänkäyttöilmoituksia haluat kartalla näkyvän kohdasta "Metsänkäyttöilmoitusten suodattimet".</span>
					</p>
					<p>
						<span>Omien alueiden käyttäminen vaatii kirjautumisen Googlen tai Facebookin tunnuksilla.</span>
					</p>
				</Panel>
				{/* <Panel header="Lista alueista" key="2">
					<UserData />
				</Panel>
				<Panel header="Metsänkäyttöilmoitusten suodattimet" key="3">
					<Filters></Filters>
				</Panel> */}
			</Collapse>
		</div>
	)
}
