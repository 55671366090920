import { Button } from 'antd';
import React, { createContext, ReactElement, useContext, useState } from 'react'
import styled from 'styled-components';
import { AppSettingsContext } from '../../App/AppSettingsContextContainer';

export interface IWizardContext {
    step: number,
    goToNextStep: () => void,
    goToPreviousStep: () => void
};

interface IProps {
    children: ReactElement[],
    onCancel: () => void
}

export const WizardContext = createContext({} as IWizardContext);

interface IWizardStyleProps {
    isMobile: boolean
}

const WizardStyle = styled.div<IWizardStyleProps>`
    margin-top: 2em;
    margin-right: 2em;
    margin-left: 2em;

    margin-bottom: ${props => props.isMobile ? "1em" : 0};
`;

const CancelButtonContainer = styled.div`
    margin-top: 1em;
`;

export default function Wizard(props: IProps) {
    const [step, setStep] = useState(0);
    const appSettingsContext = useContext(AppSettingsContext);

    const goToNextStep = () => {
        setStep(step + 1);
    }

    const goToPreviousStep = () => {
        setStep(step - 1);
    }

    return (
        <WizardStyle isMobile={appSettingsContext.isMobile}>
            <WizardContext.Provider value={{ step, goToPreviousStep, goToNextStep }}>
                {props.children}
            </WizardContext.Provider>
            <CancelButtonContainer>
                <Button type='dashed' onClick={() => props.onCancel && props.onCancel()}>Peruuta</Button>
            </CancelButtonContainer>
        </WizardStyle>
    )
}