import { Typography } from 'antd';
import { ContentContainer } from '../AlertsConfiguration/components/ContentContainer';
import { HeroImage } from '../FrontPage/FrontPage';

export default function InformationAboutService() {
    return (
        <>
            <HeroImage>
                <ContentContainer>
                    <Typography.Title level={3}>Palvelun tekijä</Typography.Title>
                    <p>
                        Tämän palvelun on tehnyt Keski-Suomessa asuva yksityishenkilö.
                    </p>
                    <Typography.Title level={3}>Palvelun tarkoitus</Typography.Title>
                    <p>
                        Luontovahti.fi yhdistää metsänkäyttöilmoitukset, luonnonmetsät, lajihavainnot sekä tärkeät elinympäristöt (Metsälain 10 § kohteet) yhteen paikkaan ja lisäksi näyttää,
                        missä ne menevät päällekkäin. Näin kuka tahansa pystyy tutkimaan, kuinka metsiämme hyödynnetään, ja arvioimaan, tehdäänkö se luontoa ja luonnonsuojelulakeja kunnioittavalla
                        tavalla.</p>
                    <p>
                        Em. tiedot pystyy kyllä näkemään myös mm. Metsäkeskuksen omilta kartoilta, Laji.fi-palvelusta ja metsänomistaja omista metsistään Metsään.fi-palvelusta,
                        mutta eri palveluiden ja karttojen tutkiminen on työlästä. Täällä ne näkee samasta paikasta.
                    </p>
                    <p>
                        Käyttäjä voi lisäksi tilata palvelusta sähköpostihälytykset, jolloin tiedon uusista luonnonmetsiin, lajihavaintoihin tai elinympäristöihin osuvista metsänkäyttöilmoituksista saa välittömästi.
                    </p>
                    <Typography.Title level={3}>Palvelun kehityksen tukijat</Typography.Title>
                    <p>
                        Tämän palvelun kehitykseen on keväällä 2023 saatu apurahaa Suomen Luonnonsuojelun Säätiön Rafael Kuusakosken muistorahastolta sekä Vuokon Luonnonsuojelusäätiöltä. Suuret kiitokset!
                    </p>
                </ContentContainer>
            </HeroImage>
        </>
    )
}