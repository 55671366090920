import UniqueValueRenderer from "@arcgis/core/renderers/UniqueValueRenderer";
import SimpleFillSymbol from "@arcgis/core/symbols/SimpleFillSymbol";

const notificationsRenderer = new UniqueValueRenderer({
    valueExpression: "When($feature.DECLARATIONSTATE == '50', $feature.CUTTINGREALIZATIONPRACTICE + 'old', $feature.CUTTINGREALIZATIONPRACTICE + '')",
    fieldDelimiter: ", ",
    defaultSymbol: new SimpleFillSymbol(),
    uniqueValueInfos: [
        {
            value: "1",
            symbol: new SimpleFillSymbol({
                color: [45, 54, 137, 255]
            })
        },
        {
            value: "1old",
            symbol: new SimpleFillSymbol({
                color: [45, 54, 137, 255],
                style: "diagonal-cross"
            })
        },
        {
            value: "2",
            symbol: new SimpleFillSymbol({
                color: [190, 75, 28, 255]
            })
        },
        {
            value: "2old",
            symbol: new SimpleFillSymbol({
                color: [190, 75, 28, 255],
                style: "diagonal-cross"
            })
        },
        {
            value: "3",
            symbol: new SimpleFillSymbol({
                color: [22, 125, 63, 255]
            })
        },
        {
            value: "3old",
            symbol: new SimpleFillSymbol({
                color: [22, 125, 63, 255],
                style: "diagonal-cross"
            })
        },
        {
            value: "4",
            symbol: new SimpleFillSymbol({
                color: [120, 49, 0, 255]
            })
        },
        {
            value: "4old",
            symbol: new SimpleFillSymbol({
                color: [120, 49, 0, 255],
                style: "diagonal-cross"
            })
        },
        {
            value: "5",
            symbol: new SimpleFillSymbol({
                color: [164, 210, 51, 255]
            })
        },
        {
            value: "5old",
            symbol: new SimpleFillSymbol({
                color: [164, 210, 51, 255],
                style: "diagonal-cross"
            })
        },
        {
            value: "6",
            symbol: new SimpleFillSymbol({
                color: [150, 82, 159, 255]
            })
        },
        {
            value: "6old",
            symbol: new SimpleFillSymbol({
                color: [150, 82, 159, 255],
                style: "diagonal-cross"
            })
        },
        {
            value: "7",
            symbol: new SimpleFillSymbol({
                color: [150, 82, 159, 255]
            })
        },
        {
            value: "7old",
            symbol: new SimpleFillSymbol({
                color: [150, 82, 159, 255],
                style: "diagonal-cross"
            })
        },
        {
            value: "8",
            symbol: new SimpleFillSymbol({
                color: [150, 82, 159, 255]
            })
        },
        {
            value: "8old",
            symbol: new SimpleFillSymbol({
                color: [150, 82, 159, 255],
                style: "diagonal-cross"
            })
        },
        {
            value: "9",
            symbol: new SimpleFillSymbol({
                color: [0, 0, 0, 255]
            })
        },
        {
            value: "9old",
            symbol: new SimpleFillSymbol({
                color: [0, 0, 0, 255],
                style: "diagonal-cross"
            })
        },
        {
            value: "10",
            symbol: new SimpleFillSymbol({
                color: [0, 0, 0, 255]
            })
        },
        {
            value: "10old",
            symbol: new SimpleFillSymbol({
                color: [0, 0, 0, 255],
                style: "diagonal-cross"
            })
        },
        {
            value: "15",
            symbol: new SimpleFillSymbol({
                color: [0, 0, 0, 255]
            })
        },
        {
            value: "15old",
            symbol: new SimpleFillSymbol({
                color: [0, 0, 0, 255],
                style: "diagonal-cross"
            })
        },
        {
            value: "16",
            symbol: new SimpleFillSymbol({
                color: [0, 0, 0, 255]
            })
        },
        {
            value: "16old",
            symbol: new SimpleFillSymbol({
                color: [0, 0, 0, 255],
                style: "diagonal-cross"
            })
        },
        {
            value: "18",
            symbol: new SimpleFillSymbol({
                color: [0, 0, 0, 255]
            })
        },
        {
            value: "18old",
            symbol: new SimpleFillSymbol({
                color: [0, 0, 0, 255],
                style: "diagonal-cross"
            })
        },
        {
            value: "11",
            symbol: new SimpleFillSymbol({
                color: [225, 0, 153, 255]
            })
        },
        {
            value: "11old",
            symbol: new SimpleFillSymbol({
                color: [225, 0, 153, 255],
                style: "diagonal-cross"
            })
        },
        {
            value: "12",
            symbol: new SimpleFillSymbol({
                color: [150, 10, 10, 255]
            })
        },
        {
            value: "12old",
            symbol: new SimpleFillSymbol({
                color: [150, 10, 10, 255],
                style: "diagonal-cross"
            })
        },
        {
            value: "17",
            symbol: new SimpleFillSymbol({
                color: [150, 10, 10, 255]
            })
        },
        {
            value: "17old",
            symbol: new SimpleFillSymbol({
                color: [150, 10, 10, 255],
                style: "diagonal-cross"
            })
        },
        {
            value: "13",
            symbol: new SimpleFillSymbol({
                color: [69, 69, 69, 255]
            })
        },
        {
            value: "13old",
            symbol: new SimpleFillSymbol({
                color: [69, 69, 69, 255],
                style: "diagonal-cross"
            })
        },
        {
            value: "14",
            symbol: new SimpleFillSymbol({
                color: [219, 177, 34, 255]
            })
        },
        {
            value: "14old",
            symbol: new SimpleFillSymbol({
                color: [219, 177, 34, 255],
                style: "diagonal-cross"
            })
        },
        {
            value: "19",
            symbol: new SimpleFillSymbol({
                color: [224, 151, 103, 255]
            })
        },
        {
            value: "19old",
            symbol: new SimpleFillSymbol({
                color: [224, 151, 103, 255],
                style: "diagonal-cross"
            })
        },
        {
            value: "20",
            symbol: new SimpleFillSymbol({
                color: [98, 18, 68, 255]
            })
        },
        {
            value: "20old",
            symbol: new SimpleFillSymbol({
                color: [98, 18, 68, 255],
                style: "diagonal-cross"
            })
        },
        {
            value: "21",
            symbol: new SimpleFillSymbol({
                color: [98, 18, 68, 255]
            })
        },
        {
            value: "21old",
            symbol: new SimpleFillSymbol({
                color: [98, 18, 68, 255],
                style: "diagonal-cross"
            })
        },
        {
            value: "22",
            symbol: new SimpleFillSymbol({
                color: [0, 198, 94, 255]
            })
        },
        {
            value: "22old",
            symbol: new SimpleFillSymbol({
                color: [0, 198, 94, 255],
                style: "diagonal-cross"
            })
        },
        {
            value: "23",
            symbol: new SimpleFillSymbol({
                color: [0, 198, 94, 255]
            })
        },
        {
            value: "23old",
            symbol: new SimpleFillSymbol({
                color: [0, 198, 94, 255],
                style: "diagonal-cross"
            })
        }]
});

export default notificationsRenderer;