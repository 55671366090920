import { createContext, ReactElement, useContext, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { AppSettingsContext } from '../../App/AppSettingsContextContainer';
import { MapContext } from '../../App/MapContextContainer';

interface IMobileNavProps {
	isOpen: boolean,
	mobileMenuBackground: string
}

const StyledMobileNav = styled.nav<IMobileNavProps>`
	display: ${props => props.isOpen ? "flex" : "none"};
	flex-direction: column;
	margin-bottom: 0.5em;		
	margin-left: auto;	
	border: black solid 1px;
    border-left: none;
    border-radius: 0 10px 10px 10px;
	z-index: 1000;
	left: calc(30px + 1em + 6px);
	width: min(30rem, 85%);
	position: absolute;
	margin-right: 1em;
	overflow-y: auto;

	max-height: 90%;

	@media only screen and (min-width: 1080px) { 
		width: max(30rem, 15%);
	}
`;

const ClickOutsideMenuContainer = styled.div`
	top: 0;
	z-index: 999;
	left: 0;
	width: 100%;
	height: 100%;
	position: absolute;	

	opacity: 0.45;
	background-color: black;
`;

const MobileMenuContainer = styled.div<IMobileNavProps>`
	display: ${props => props.isOpen ? "block" : "none"};	
	z-index: 1000;
	width: 100%;
	background: ${props => props.mobileMenuBackground};	
	max-height: 100%;
`;

const MobileMenuContentsContainer = styled.div`
	flex-direction: column;
	display: flex;
	padding: 1em;

	max-height: 100%;

	& a {
		line-height: 1.5em;
		font-size: 150%;
	}
`;

const IconContainer = styled.div<IMobileNavProps>`
	background-color: rgba(255, 255, 255, 0.7);

	border: solid black 1px;
	border-right: none;

	position: absolute;
	left: 3px;
	width: calc(30px + 1em + 3px);
	display: flex;
	flex-direction: column;
	z-index: 1000;
`;

export interface IMenuContext {
	close: () => void,
	open: (state: IMenuOpenState) => void,
	state?: IMenuOpenState
}

export enum IMenuOpenState {
	filters,
	main,
	help,
	alerts,
	userData,
	closed
}

export const MenuContext = createContext({} as IMenuContext);

export interface IMenuContentProps {
	state: IMenuOpenState
}

export interface IMenuProps {
	icons: ReactElement[],
	render: (state: IMenuOpenState) => {}
}

export default function MapMenu(props: IMenuProps): ReactElement {
	const [state, setOpenState] = useState<IMenuOpenState>(IMenuOpenState.closed);
	const { colors, appliedFilters } = useContext(AppSettingsContext);
	const { pointToNavigateTo } = useContext(MapContext);
	const mobileMenuRef = useRef(null);
	const iconsRef = useRef(null);

	const close = () => {
		setOpenState(IMenuOpenState.closed);

		document.documentElement.className = "";
	};

	const open = (state: IMenuOpenState) => {
		setOpenState(state);

		document.documentElement.className = "mobile-menu-open";
	};

	useEffect(() => {
		close();
	}, []);

	useEffect(() => {
		return () => {
			close();
		}
	}, [pointToNavigateTo]);

	useEffect(() => {
		return () => {
			close();
		}
	}, [appliedFilters]);

	return (
		<MenuContext.Provider value={{ close, open, state }}>
			<IconContainer isOpen={state !== IMenuOpenState.closed} mobileMenuBackground={colors[6]} ref={iconsRef}>
				{props.icons.map((icon, index) => { return <div key={index}>{icon}</div>})}
			</IconContainer>
			{state !== IMenuOpenState.closed && <ClickOutsideMenuContainer onClick={() => close()}></ClickOutsideMenuContainer>}
			<StyledMobileNav isOpen={state !== IMenuOpenState.closed} mobileMenuBackground={""} ref={mobileMenuRef}>
				<MobileMenuContainer isOpen={state !== IMenuOpenState.closed} mobileMenuBackground={colors[2]}>
					<MobileMenuContentsContainer>
						{state !== IMenuOpenState.closed ? props.render(state) : <></>}
					</MobileMenuContentsContainer>
				</MobileMenuContainer>
			</StyledMobileNav>
		</MenuContext.Provider>
	)
}
