import { Configuration, RedirectRequest } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: "53014c2f-8b8e-4f97-b7b0-387a289c4c9a",
        authority: "https://luontovahtifi.b2clogin.com/luontovahtifi.onmicrosoft.com/B2C_1_luontovahti_dev",
        knownAuthorities: ["luontovahtifi.b2clogin.com"],
        redirectUri: "/",
        postLogoutRedirectUri: "/"
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: RedirectRequest = {
    scopes: []
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft-ppe.com/v1.0/me"
};

export const protectedResources = {
    api: {
      endpoint: "http://localhost:7071/api/",
      scopes: ["https://luontovahtifi.onmicrosoft.com/api-dev/api-access"],
    },
  }