import styled from 'styled-components';

export const ContentContainer = styled.div`
    margin-top: 2em;

    padding-left: 1em;
    padding-right: 1em;
    padding-top: 1em;
    padding-bottom: 1em;
    max-width: 100%;

    background-color: white;
    
    @media only screen and (min-width: 920px) {
        max-width: 60%;
        margin-left: 20%;
    }

    @media only screen and (min-width: 1440px) {
        max-width: 50%;    
        margin-left: 25%;
    }

    color: black;
`;
