import React, { useCallback, useContext } from 'react'
import { AppSettingsContext } from '../../App/AppSettingsContextContainer';
import { Select } from 'antd'
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

const languages = [
    { value: "fi", title: "Suomeksi", flag: <img src="/Flag_of_Finland.svg" width="20" height="20" /> },
    // { value: "sv", title: "På svenska", flag: <img src="/Flag_of_Sweden.svg" width="20" height="20" /> },
    { value: "en", title: "In English", flag: <img src="/US-UK_Flag.svg" width="20" height="20" /> },
];

export default function LanguageSelector() {
    const appSettingsContext = useContext(AppSettingsContext);
    const { isMobile } = appSettingsContext;
    const { t } = useTranslation("", { useSuspense: true });

    const optionRender = useCallback((lang: any) => {
        return <div style={{ "width": "6em", "display": "flex", "justifyContent": "space-between" }}>
            <div>
                {lang.data.title}
            </div>
            <div>
                {lang.data.flag}
            </div>
        </div>
    }, [languages]);

    return (
        <div>
            {i18next.resolvedLanguage && <Select defaultValue={i18next.resolvedLanguage}
                popupMatchSelectWidth={false}
                onChange={(lang: any) => i18next.changeLanguage(lang.value)}
                bordered={false} optionLabelProp={isMobile ? "flag" : "flag"}
                labelInValue={true} options={languages} optionRender={optionRender}>
            </Select>}
        </div>
    )
}