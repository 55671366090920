import Extent from "@arcgis/core/geometry/Extent";
import MapView from "@arcgis/core/views/MapView";
import WebMap from "@arcgis/core/WebMap";
import Expand from "@arcgis/core/widgets/Expand";
import LayerList from "@arcgis/core/widgets/LayerList";
import Track from "@arcgis/core/widgets/Track";
import { useContext, useEffect, useMemo, useState } from "react";
import { AppSettingsContext } from "./AppSettingsContextContainer";
import { MapContext } from "./MapContextContainer";
import * as projection from "@arcgis/core/geometry/projection";
import Geometry from "@arcgis/core/geometry/Geometry";
import WMTSLayer from "@arcgis/core/layers/WMTSLayer";
import esriConfig from "@arcgis/core/config";
import { DECLARATIONS_MIN_SCALE_TO_SHOW } from "./useNotificationsLayer";
import useTestLayer from "./useTestLayer";
import GeoJSONLayer from "@arcgis/core/layers/GeoJSONLayer";
import useBboxUpdate from "../pages/ForestsNearUs/components/Map/useBboxUpdate";
import LabelClass from "@arcgis/core/layers/support/LabelClass";
import SimpleRenderer from "@arcgis/core/renderers/SimpleRenderer";
import SimpleMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol";
import SimpleFillSymbol from "@arcgis/core/symbols/SimpleFillSymbol";
import ScaleBar from "@arcgis/core/widgets/ScaleBar.js";
import useSetUpLayerListActions from "./useSetUpLayerListActions";
import { useTranslation } from "react-i18next";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import errors from "./errors";

const propertyLinesLayerUrl = `https://avoin-paikkatieto.maanmittauslaitos.fi/kiinteisto-avoin/simple-features/v3/collections/KiinteistorajanSijaintitiedot/items?api-key=${process.env.REACT_APP_MML_API_KEY}`;
const propertyIdsLayerUrl = `https://avoin-paikkatieto.maanmittauslaitos.fi/kiinteisto-avoin/simple-features/v3/collections/KiinteistotunnuksenSijaintitiedot/items?api-key=${process.env.REACT_APP_MML_API_KEY}`;
const terrainMapLayerUrl = `https://avoin-karttakuva.maanmittauslaitos.fi/avoin/wmts/1.0.0/WMTSCapabilities.xml?api-key=${process.env.REACT_APP_MML_API_KEY}`;

export function useSetUpMap(elementRef: React.MutableRefObject<null>, mapId: string) {
    const mapContext = useContext(MapContext);
    const { isMobile } = useContext(AppSettingsContext);
    const defaultZoomLevel = isMobile ? 5 : 5;
    const [originalExtent, setOriginalExtent] = useState<Extent | null>(null);
    const { t, i18n } = useTranslation("common");

    esriConfig.apiKey = process.env.REACT_APP_ESRI_API_KEY || "willNotWork";

    const webmap = useMemo(() => new WebMap({
        portalItem: {
            id: mapId
        }
    }), [mapId]);

    const view = useMemo(() => new MapView({
        map: webmap
    }), [webmap]);

    useTestLayer(propertyLinesLayerUrl, { ...errors.layerLoadingFailed, replacementValues: ["kiinteistörajat"] });
    useTestLayer(propertyIdsLayerUrl, { ...errors.layerLoadingFailed, replacementValues: ["kiinteistötunnukset"] });
    useTestLayer(terrainMapLayerUrl, { ...errors.layerLoadingFailed, replacementValues: ["maastokartta"] });

    const labelClass = new LabelClass({
        symbol: {
            type: "text",
            color: "black",
            font: {
                weight: "bold",
            },
            backgroundColor: [213, 184, 255, 0.75],
            borderLineColor: "green",
            borderLineSize: 0,
            yoffset: 5
        },
        labelPlacement: "center-center",
        labelExpressionInfo: {
            expression: "$feature.kiinteistotunnuksenEsitysmuoto"
        }
    });

    const propertyIdsLayer = useMemo(() => new GeoJSONLayer({
        url: propertyIdsLayerUrl,
        visible: false,
        title: t("kiinteistötunnuksetKerrosOtsikko"),
        renderer: new SimpleRenderer({
            symbol: new SimpleMarkerSymbol({
                size: 0
            })
        }),
        labelingInfo: [labelClass],
        minScale: 20000,
        copyright: "Maanmittauslaitos",
        customParameters: {
            "api-key": `${process.env.REACT_APP_MML_API_KEY}`
        },
    }), []);

    const useBboxUpdateForOpenAreas = useBboxUpdate(view, propertyIdsLayer, {
        "api-key": `${process.env.REACT_APP_MML_API_KEY}`
    });

    const propertyBordersLayer = useMemo(() => new GeoJSONLayer({
        url: propertyLinesLayerUrl,
        visible: false,
        title: t("kiinteistörajatKerrosOtsikko"),
        minScale: 20000,
        renderer: new SimpleRenderer({
            symbol: new SimpleFillSymbol({
                outline: {
                    color: "red",
                    width: 2
                }
            }),
        }),
        copyright: "Maanmittauslaitos",
        customParameters: {
            "api-key": `${process.env.REACT_APP_MML_API_KEY}`
        },
    }), []);

    const useBboxUpdateForPropertyBorders = useBboxUpdate(view, propertyBordersLayer, {
        "api-key": `${process.env.REACT_APP_MML_API_KEY}`
    });

    let layerList = useMemo(() => new LayerList(), []);

    useSetUpLayerListActions(layerList);

    const stateOwnedLandLayer = useMemo(() => new FeatureLayer({
        url: "https://services6.arcgis.com/f3AgXyz2GHmCyuKI/arcgis/rest/services/valtion_maat_jaoteltu_dev_2/FeatureServer/0",
        visible: false,
        opacity: 0.5,
        title: t("valtionMetsätKerrosOtsikko"),
        renderer: new SimpleRenderer({
            symbol: new SimpleFillSymbol({
                color: "#74b566",
                outline: {
                    color: "black",
                    width: 1
                }
            }),
        }),
    }), []);

    const terrainMapLayer = useMemo(() => new WMTSLayer({
        url: terrainMapLayerUrl,
        title: t("maastokarttaKerrosOtsikko"),
        activeLayer: {
            id: "maastokartta",
        },
        minScale: DECLARATIONS_MIN_SCALE_TO_SHOW,
        customParameters: {
            "api-key": `${process.env.REACT_APP_MML_API_KEY}`
        },
        visible: false,
        copyright: "Maanmittauslaitos"
    }), []);

    useEffect(() => {
        let l = webmap.findLayerById(propertyBordersLayer.id);

        if (l) {
            l.title = t("kiinteistörajatKerrosOtsikko");
        }

        l = webmap.findLayerById(propertyIdsLayer.id);

        if (l) {
            l.title = t("kiinteistötunnuksetKerrosOtsikko");
        }

        l = webmap.findLayerById(terrainMapLayer.id);

        if (l) {
            l.title = t("maastokarttaKerrosOtsikko");
        }

        l = webmap.findLayerById(stateOwnedLandLayer.id);

        if (l) {
            l.title = t("valtionMetsätKerrosOtsikko");
        }
    }, [i18n.language, t]);

    useEffect(() => {
        if (elementRef.current !== null && view.container === null) {
            webmap.add(terrainMapLayer);
            webmap.add(propertyBordersLayer);
            webmap.add(propertyIdsLayer);
            webmap.add(stateOwnedLandLayer);

            view.container = elementRef.current;

            view.ui.move("zoom", "bottom-right");

            let scaleBar = new ScaleBar({
                view: view,
                unit: "metric",
                style: "ruler",
            });

            view.ui.add(scaleBar, "bottom-left");

            let locateWidget = new Track({
                viewModel: {
                    view: view, // assigns the locate widget to a view                 
                },
                geolocationOptions: {
                    maximumAge: 30000,
                    timeout: 15000,
                    enableHighAccuracy: true
                }
            });

            view.ui.add(locateWidget, "bottom-right");

            layerList.view = view;

            const layerListExpand = new Expand({
                expandIconClass: "esri-icon-layers",
                view: view,
                content: layerList
            });

            view.ui.add(layerListExpand, "bottom-right");

            if (isMobile) {
                view.popup.collapseEnabled = false;

                const mobileSidePanelToggle = document.getElementById("mobile-side-panel-toggle");

                if (mobileSidePanelToggle) {
                    view.ui.add(mobileSidePanelToggle, "manual");
                }
            }

            const declarationFreshnessIndicator = document.getElementById("declarations-freshness-indicator");

            if (declarationFreshnessIndicator) {
                view.ui.add(declarationFreshnessIndicator, "top-left");
            }

            const asyncCall = async () => {
                await view.when();

                setOriginalExtent(view.extent.clone());

                console.log("Map and View are ready");

                mapContext.setMapAndViewReady(view);
            };

            asyncCall();
        }

        return () => {
            console.log("destroy map");
        };
    }, []);

    useEffect(() => {
        if (mapContext.mapAndViewReady && mapContext.pointToNavigateTo !== null) {
            if (mapContext.pointToNavigateTo.type === "IPointWithZoom") {
                view.goTo({
                    center: mapContext.pointToNavigateTo.point,
                    zoom: mapContext.pointToNavigateTo.zoom
                });
            }
            else if (mapContext.pointToNavigateTo.type === "point") {
                view.goTo({
                    center: mapContext.pointToNavigateTo,
                    zoom: isMobile ? 10 : 12
                });
            }
            else if ((mapContext.pointToNavigateTo.type === "polygon" || mapContext.pointToNavigateTo.type === "extent") && originalExtent) {
                const originalExtentOfView = originalExtent.clone();

                const extentToIntersectWith = (() => {
                    if (mapContext.pointToNavigateTo.spatialReference.wkid === originalExtentOfView.spatialReference.wkid) {
                        return mapContext.pointToNavigateTo.extent;
                    }
                    else {
                        const projected = projection.project(mapContext.pointToNavigateTo, originalExtentOfView.spatialReference) as Geometry;

                        return projected.extent;
                    }
                })();

                const newExtent = originalExtentOfView.extent.intersection(extentToIntersectWith);

                view.extent = newExtent ? newExtent.expand(1.3) : view.extent;
            }

            mapContext.navigateToPoint(null);
        }
    }, [mapContext.mapAndViewReady, mapContext.pointToNavigateTo, defaultZoomLevel]);

    useEffect(() => {
        if (mapContext.mapAndViewReady) {
            view.goTo({
                center: [25.838851, 65.069653],
                zoom: defaultZoomLevel
            });
        }
    }, [mapContext.mapAndViewReady, defaultZoomLevel, view]);

    return { webmap, view };
}
