import styled from 'styled-components';
import AppSettingsContextContainer, { AppSettingsContext } from './AppSettingsContextContainer';
import GlobalStyles from './GlobalStyles';
import MapContextContainer from './MapContextContainer';
import MainContent from './MainContent';

// MSAL imports
import { MsalProvider } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import { ConfigProvider } from 'antd';

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;

  position: fixed;  
  height: 100%;
  width: 100%;

  @media screen and (min-width: 1080px) {
    flex-direction: column;
  }
`;

type AppProps = {
  pca: IPublicClientApplication
};

function App({ pca }: AppProps) {
  return (
    <MsalProvider instance={pca}>
      <MapContextContainer>
        <AppSettingsContextContainer>
          <AppSettingsContext.Consumer>
            {value => <>
              <ConfigProvider theme={{
                hashed: false, token: {
                    fontFamily: "Roboto Condensed, sans-serif",
                }
              }}>
                <GlobalStyles spinnerBackgroundColor={value.colors[9]} />
                <AppContainer>
                  <MainContent />
                </AppContainer>
              </ConfigProvider>
            </>}
          </AppSettingsContext.Consumer>
        </AppSettingsContextContainer>
      </MapContextContainer>
    </MsalProvider>
  );
}

export default App;
