import { Typography } from 'antd';
import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components'
import { AppSettingsContext } from '../../App/AppSettingsContextContainer';
import SocialMediaButtons from '../../components/SocialMediaButtons/SocialMediaButtons';
import { Trans, useTranslation } from 'react-i18next';

export const HeroImage = styled.div`
    /* Use "linear-gradient" to add a darken background effect to the image (photographer.jpg). This will make the text easier to read */
    background-image: url("/background-image-2-mobile-new.jpg");

    /* Set a specific height */
    height: 100%;

    //max-width: 2000px;
    
    /* Position and center the image to scale nicely on all screens */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    @media only screen and (min-width: 920px) {
        background-image: url("/background-image-2-1200width.png");
    }

    @media only screen and (min-width: 1440px) {
        background-image: url("/background-image-2-1800width.png");
    }
`;

const HeroText = styled.div`
    /* text-align: center; */
    position: absolute;
    top: 0%;
    left: 0%;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 1em;
    max-width: 100%;
    /* height: 100%; */

    background-color: white;
    
    @media only screen and (min-width: 920px) {
        max-width: 70%;
        top: 5%;
        left: 4%;
    }

    @media only screen and (min-width: 1440px) {
        max-width: 30%;    
        top: 8%;
        left: 4%;
    }

    //transform: translate(-50%, -50%);
    color: black;
`;

interface IFooterProps {
    backgroundColor: string
}

export const Footer = styled.footer<IFooterProps>`
    background-color: ${props => props.backgroundColor};
    color: white;
    padding-top: 1em;
    padding-left: 1em;
    padding-bottom: 1em;

    display: flex;
    flex-direction: column;
    align-items: center;
`;

const StyledLink = styled(Link)`
    color: white;
`;

const StyledHref = styled.a`
    color: white;
`;

const FrontPageContainer = styled.div`
    height: 100%;
`;

export default function FrontPage() {
    const appSettingsContest = useContext(AppSettingsContext);
    const { t } = useTranslation("frontpage", { useSuspense: true });

    return (<><FrontPageContainer>
        <HeroImage>
            <HeroText>
                {appSettingsContest.isMobile === false && <Typography.Title level={1}>Luontovahti</Typography.Title>}

                <p>
                    <Trans i18nKey={"frontpage:rivi1"}>
                        Luontovahti seuraa Metsäkeskuksen <a target="blank" href="https://www.metsakeskus.fi/fi/palvelut/metsankayttoilmoitus">metsänkäyttöilmoituksia</a>
                    </Trans>
                </p>

                <p>
                    <Trans i18nKey={"frontpage:rivi2"}>
                        Palvelusta voit mm. seurata Suomesta kartoitettujen luonnonmetsien tilaa. Siirry katselemaan luonnonmetsiä <Link to={"/luonnonmetsat"}>tästä</Link>.
                    </Trans>
                </p>
                <p>
                    <Trans i18nKey={"frontpage:rivi3"}>
                        Voit myös tutkia, osuuko metsänkäyttöilmoituksiin Laji.fiihin tallennettuja havaintoja uhanalaisista lajeista. Kartta tähän tarkoitukseen löytyy <Link to={"/hakkuutjalajit"}>täältä</Link>.
                    </Trans>
                </p>
                <p>
                    <Trans i18nKey={"frontpage:rivi4"}>
                        Myös tärkeät elinympäristöt (Metsälain 10 § kohteet) ja niihin osuvat metsänkäyttöilmoitukset löytyvät palvelusta <Link to={"/hakkuutjaelinymparistot"}>täältä</Link>.
                    </Trans>
                </p>
                <p>
                    <Trans i18nKey={"frontpage:rivi5"}>
                        Voit tilata sähköpostiisi hälytyksen, jos uusi metsänkäyttöilmoitus osuu luonnonmetsään tai lajihavaintoon. Tilaa hälytykset <Link to={"/halytykset"}>täältä</Link>.
                    </Trans>
                </p>
                <p>
                    <Trans i18nKey={"frontpage:rivi6"}>
                        Luontovahdilla on myös <a target="_blank" href="https://blog.luontovahti.fi">blogi</a>, jossa kerrotaan lisää palvelun ominaisuuksista ja kehityksestä.
                    </Trans>
                </p>
            </HeroText>
        </HeroImage>

    </FrontPageContainer>
        <Footer backgroundColor={appSettingsContest.colors[9]}>
            <div style={{ "paddingTop": "0.5em" }}>
                <SocialMediaButtons url={`${window.location.href}`}></SocialMediaButtons>
            </div>
            <StyledHref target="_blank" href="https://blog.luontovahti.fi">{t("blogi")}</StyledHref>
            <StyledLink to={'/tietojapalvelusta'}>{t("tietojaPalvelusta")}</StyledLink>
            <StyledLink to={"/palaute"}>{t("annaPalautetta")}</StyledLink>
            &#169; Luontovahti.fi
        </Footer>
    </>
    )
}