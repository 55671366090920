import React, { useContext } from 'react';
import {
    CaretDownFilled,
    CaretUpFilled
} from '@ant-design/icons';
import { AppSettingsContext } from '../../App/AppSettingsContextContainer';
import styled from 'styled-components';

const SidePanelToggleControlMobileDiv = styled.div`
    left: calc(50% - 20px);
    position: absolute;
    bottom: 30px;
    background-color: green;
    width: 40px;
    height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

export function SidePanelToggleControlMobile() {
    const appSettingsContext = useContext(AppSettingsContext);

    return (
        <SidePanelToggleControlMobileDiv id="mobile-side-panel-toggle"
            onClick={() => appSettingsContext.setIsMobileBottomPanelOpen(!appSettingsContext.isMobileBottomPanelOpen)}>
            {appSettingsContext.isMobileBottomPanelOpen ? <CaretDownFilled /> : <CaretUpFilled />}
        </SidePanelToggleControlMobileDiv>
    );
}
