import React, { useContext } from 'react'
import styled from 'styled-components'
import { AppSettingsContext } from '../../App/AppSettingsContextContainer';
import { Footer, HeroImage } from './FrontPage';

const FrontPageContainer = styled.div`
    height: 100%;
`;

export default function FrontPageSuspended() {
    const appSettingsContest = useContext(AppSettingsContext);

    return (<>
        <FrontPageContainer>
            <HeroImage />
        </FrontPageContainer>
        <Footer backgroundColor={appSettingsContest.colors[9]}>
            &#169; Luontovahti.fi
        </Footer>
    </>
    )
}