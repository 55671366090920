import { Feature, Polygon, Point } from "@turf/helpers";
import { useCallback } from 'react';
import { useTranslation } from "react-i18next";
import { IItemWithLanguageOptions } from "../components/TranslatedOption/TranslatedOption";

export const cuttingRealizationPracticesEsriSQLReducer = (prev: string, curr: string) => {
    const splitByComma = curr.split(","); // This might contain several ids

    let currString = "";

    for (const part of splitByComma) {
        currString = currString + `(cuttingRealizationPractices LIKE '%.${part}.%') OR `;
    }

    // Remove extra OR
    currString = currString.substring(0, currString.length - 3);

    if (prev === "") {
        return currString;
    }

    return prev + " OR " + currString;
}

export const getMinimumClashIntersectionSizeToConsider = (clashSeverity: number | undefined) => {
    if (clashSeverity === undefined) {
        return undefined;
    }

    switch (clashSeverity) {
        case -2:
            return 0;
        case -1:
            return 0.001;
        case 0:
            return 0.2;
        default:
            return 0.001;
    }
};

export const makeCuttingRealizationPracticePropQueryable = (f: Feature<Polygon | Point>) => {
    if (f.properties) {
        const originalProp = f.properties["cuttingRealizationPractices"] as string[];

        const cuttingRealizationPracticesConcatenated = originalProp.reduce((prev, curr) => {
            return prev + curr + ".";
        }, ".");

        return {
            ...f,
            properties: {
                ...f.properties,
                cuttingRealizationPractices: cuttingRealizationPracticesConcatenated
            }
        }
    }

    return f;
};

export const numberToPastDate = (stuff?: number) => {
    if (stuff === undefined) {
        return stuff;
    }

    return new Date(new Date().setMonth(new Date().getMonth() + stuff));
};

export const getFromAndToDatesFromMonths = (howManyMonthsBack: number[]) => {
    const firstPastDate = numberToPastDate(howManyMonthsBack[0]);
    const secondPastDate = numberToPastDate(howManyMonthsBack[1]);

    if (firstPastDate && secondPastDate) {
        const secondPastDatePushedToNextMonth = new Date(secondPastDate.getFullYear(), secondPastDate?.getMonth() + 1, 1);
        const secondPastDateLastDayOfMonth = new Date(secondPastDatePushedToNextMonth.getFullYear(), secondPastDatePushedToNextMonth.getMonth(), secondPastDatePushedToNextMonth.getDate() - 1);

        return {
            fromDate: firstPastDate,
            toDate: secondPastDateLastDayOfMonth
        }
    }

    return {
        fromDate: undefined,
        toDate: undefined
    }
}

export const storageAvailable = (type: "localStorage" | "sessionStorage") => {
    try {
        const storage = window[type];
        const x = '__storage_test__';

        storage.setItem(x, x);
        storage.removeItem(x);

        return true;
    }
    catch (e) {
        return false;
    }
}

export function useLocalizedSort() {
    const { t, i18n } = useTranslation();

    const sortingFnForCuttingRealizationPractice = useCallback((item1: IItemWithLanguageOptions, item2: IItemWithLanguageOptions) => {
        if (i18n.language === "fi") {
            return item1.nameFi.localeCompare(item2.nameFi);
        }
        else if (i18n.language === "sv") {
            return item1.nameSv.localeCompare(item2.nameSv);
        }
        else {
            return item1.nameEn.localeCompare(item2.nameEn);
        }
    }, [t, i18n.language]);

    return sortingFnForCuttingRealizationPractice;
}

const firstOf042020Epoch = new Date("2020-04-01T00:00:00.000Z").getTime();
const firstOfThisMonthEpoch = new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), 1).getTime();
const epochSince = firstOfThisMonthEpoch - firstOf042020Epoch;
export const monthsSince042020: number = epochSince / 1000 / 60 / 60 / 24 / 30;
const firstOf012006Epoch = new Date("2006-01-01T00:00:00.000Z").getTime();
const epochSinceFirstDeclarations = firstOfThisMonthEpoch - firstOf012006Epoch;
export const monthsSinceFirstDeclarations: number = epochSinceFirstDeclarations / 1000 / 60 / 60 / 24 / 30;

export const PREFERRED_WKID = 4326;