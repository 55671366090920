import React, { ReactElement, useContext } from 'react';
import styled from 'styled-components';
import { AppSettingsContext } from '../../../App/AppSettingsContextContainer';
import { IMenuOpenState, MenuContext } from '../MapMenu';
import { CloseIcon } from './CloseIcon';

interface IWrapperProps {
  children: ReactElement,
  stateToShow: IMenuOpenState
}

interface IIconContainerProps {
  shouldBeInDOM: boolean,
  backgroundColor: string,
  isMobile: boolean,
  makeBorder: boolean
}

const IconContainer = styled.div<IIconContainerProps>`
  display: ${p => p.shouldBeInDOM ? "block" : "none"};
  width: 100%;
  padding-left: 0.2em;
	padding-right: 0.5em;

  @media only screen and (min-width: 1080px) { 
    padding-left: 0.4em;
	}

  border-right: ${p => p.makeBorder ? "solid black 1px" : "none"};

  background-color: ${p => p.backgroundColor};

  font-weight: bold;
  font-size: ${p => p.isMobile ? "2rem" : "1.5rem"};
`;

export function IconWrapper(props: IWrapperProps): ReactElement {
  const context = useContext(MenuContext);
  const { colors, isMobile } = useContext(AppSettingsContext);

  return (<>
    {context.state !== props.stateToShow && <IconContainer makeBorder={true} shouldBeInDOM={true} isMobile={isMobile} backgroundColor="">{props.children}</IconContainer>}
    <IconContainer makeBorder={false} shouldBeInDOM={context.state === props.stateToShow} isMobile={isMobile} backgroundColor={colors[2]}>
      <CloseIcon />
    </IconContainer>
  </>
  );
}
