import { ReactElement, useContext, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components'
import { AppSettingsContext } from '../../App/AppSettingsContextContainer';
import SocialMediaButtons from '../SocialMediaButtons/SocialMediaButtons';
import Burger from './Burger';
import { useTranslation } from 'react-i18next';

interface IMobileNavProps {
	isOpen: boolean,
	mobileMenuBackground: string
}

const StyledMobileNav = styled.nav<IMobileNavProps>`
	display: flex;
	margin-bottom: 0.5em;		
	top: 1.1em;
	margin-right: 1em;
	font-size: 80%;
	overflow: visible;
	height: 1em;

	@media only screen and (min-width: 992px) { 
		display: none;
	}
`;

const MobileMenuContainer = styled.div<IMobileNavProps>`
	display: ${props => props.isOpen ? "block" : "none"};	
	z-index: 1010;
	width: 100vw;
	height: 100vh;
	position: fixed;
	bottom: 0;
	top: 2.4em;
	right: 0;	
	opacity: 0.98;
	left: 0;
	background: ${props => props.mobileMenuBackground};	
	color: white;
`;

const MobileMenuContentsContainer = styled.div`
	flex-direction: column;
	display: flex;
	padding: 1em;

	& a {
		color: white;
		line-height: 1.5em;
		font-size: 150%;
	}
`;

const linkStyles = css`
	margin-right: 8px;
	color: black;
	text-transform: uppercase;

	&:hover {
		text-decoration: underline;
		background: lightgrey;
	}
`;

const StyledLink = styled(Link)`
	${linkStyles}	
`;

const StyledHrefA = styled.a`
	${linkStyles}
`;

const BetaText = styled.span`
	color: red;
`;

export default function MobileMenu(): ReactElement {
	const [isOpen, setOpen] = useState(false)
	const appSettingsContext = useContext(AppSettingsContext);
	const location = useLocation();
	const { t } = useTranslation("menus", { useSuspense: true });

	const setMobileMenuOpen = (isOpen: boolean) => {
		setOpen(isOpen);

		if (isOpen) {
			document.documentElement.className = "mobile-menu-open";
		}
		else {
			document.documentElement.className = "";
		}
	};

	useEffect(() => {
		setMobileMenuOpen(false);
	}, [location]);

	useEffect(() => {
		return () => {
			setMobileMenuOpen(false);
		}
	}, []);

	return (
		<>
			<StyledMobileNav isOpen={isOpen} mobileMenuBackground={""}>
				<Burger setOpen={setMobileMenuOpen} open={isOpen}></Burger>
				<MobileMenuContainer isOpen={isOpen} mobileMenuBackground={appSettingsContext.colors[9]}>
					<MobileMenuContentsContainer>
						<StyledLink to="/luonnonmetsat">{t("luonnonmetsät")}</StyledLink>
						<StyledLink to="/hakkuutjalajit">{t("lajit")}</StyledLink>
						<StyledLink to="/hakkuutjaelinymparistot">{t("elinympäristöt_m")}</StyledLink>
						<StyledLink to="/hakkuidenhistoriaa">{t("hakkuidenHistoria_m")}</StyledLink>
						<StyledLink to="/lahimetsamme">{t("lähimetsät")}</StyledLink>
						<StyledLink to="/vanhojametsia">{t("metsoPotentiaali_m")}</StyledLink>
						{/* <StyledLink to="/omatalueet">{t("omatAlueet")}</StyledLink> */}
						<hr style={{ "width": "100%" }}></hr>
						<StyledLink to="/halytykset">{t("sähköpostihälytykset")}</StyledLink>
						<hr style={{ "width": "100%" }}></hr>
						<StyledLink to="/palaute">{t("annaPalautetta")}</StyledLink>
						<StyledHrefA target='_blank' href="https://blog.luontovahti.fi">{t("blogi")}</StyledHrefA>
						<div style={{ "paddingTop": "0.5em" }}>
							<SocialMediaButtons url={`${window.location.href}`}></SocialMediaButtons>
						</div>
					</MobileMenuContentsContainer>
				</MobileMenuContainer>
			</StyledMobileNav>
		</>
	)
}
